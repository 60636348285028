@use "../../../scss/colors";
@use "../../../scss/font";
@use "../../../scss/mixins";

.LeafCircleProgressTile {
  &__tag {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 3rem;
    line-height: 1.3rem;
  }

  &__button-container {
    display: flex;
    justify-content: center;
  }

  &__button {
    background-color: transparent;
    min-width: 16.125rem;
    border: 0.125rem solid colors.$amway_black;
    border-radius: 3.125rem;
    padding: 0.5rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: GTWalsheimPro, sans-serif;
    font-weight: font.$weight--bold;
    font-size: font.$size--standard;
    box-sizing: border-box;
    color: colors.$amway_black;

    @include mixins.tablet_only {
      font-size: font.$size--medium;
    }

    @include mixins.md_m {
      font-size: font.$size--medium;
      min-height: 2.75rem;
    }
  }

  &__button-icon {
    margin-left: 0.5rem;
    cursor: pointer;

    span {
      cursor: pointer;
    }
  }

  &__button-text {
    cursor: pointer;
  }
}

.VolumeReqCircle {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;

  &__exempt {
    text-align: center;
    color: colors.$dark_grey_on_white;
  }

  &__definition {
    text-align: center;
    font-weight: font.$weight--regular;
    font-size: font.$size--standard;
    line-height: 1.25rem;
    color: colors.$dark_grey_on_white;
  }
}
