@use "../../scss/font";
@use "../../scss/colors";
@use "../../scss/mixins";

.leaf-circle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 12rem !important;
  width: 14rem !important;
}

.target-indicator {
  position: relative;
  top: 1.8rem;
  left: 2.15rem;
  transform: rotate(17deg);
  font-size: 0.5rem;
}

.title-container {
  height: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  top: 4.2rem;
}

.center-title {
  font-weight: font.$weight--bold;
  font-size: font.$size--large_l;
}

.center-subtitle {
  font-size: font.$size--medium;
}

.data-points-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  bottom: 7.6rem;
}

.dataPoints {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: colors.$disabled_gray;
  font-size: font.$size--standard;
  font-family: font.$family--header;

  @include mixins.md_s {
    font-size: font.$size--medium;
  }
}

.LeafProgressHalfCircle {
  height: 16.25rem;
  width: 16.25rem;

  &__Loader {
    padding-top: 1.5rem;
    height: 13.5rem;
    width: 13.5rem;
  }

  .pf-v5-c-chart {
    * > text > tspan:nth-child(1) {
      font-weight: 900;
    }

    svg > text > tspan:nth-child(2) {
      fill: black !important;
    }

    svg > g > path:nth-child(2) {
      fill: #f0f0f0 !important;
    }
  }
}
