@use "../../scss/colors";

.leaf-checkbox {
  display: flex;
  align-items: center;
  gap: 0.875rem;
  font-size: 1rem;
  padding: 0.625rem 0;
  line-height: 1.5rem;

  input[type="checkbox"] {
    /* removing default appearance */
    appearance: none;

    /* creating a custom design */
    width: 1.6em;
    height: 1.6em;
    min-width: 1.6em;
    border-radius: 0.15em;
    margin-right: 0.5em;
    border: 0.15em solid colors.$amway_black;
    outline: none;
    cursor: pointer;
  }

  input.checked {
    background-color: colors.$amway_black;
    position: relative;
  }

  input.checked::before {
    content: "\2713";
    font-size: 1.5em;
    color: colors.$white;
    position: absolute;
    right: 0.065rem;
    top: -0.2rem;
  }

  input[type="checkbox"]:disabled {
    border-color: colors.$disabled;
    background-color: colors.$disabled;
    color: colors.$disabled;
  }

  input[type="checkbox"]:focus {
    box-shadow: 0 0.13rem 1rem 0 colors.$blue;
  }
}
