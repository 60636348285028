@use "../../scss/colors";

.LeafMessageBox {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.625rem;
  border-radius: 0.75rem;
  width: 100%;
}

.icon {
  margin: 0.25rem;
}

.red {
  background: colors.$background_light_red;
  border: 1px solid colors.$outline_red;
  color: colors.$error_red;
}

.orange {
  background: colors.$extra_light_orange;
  border: 1px solid colors.$outline_orange;
  color: colors.$orange;
}
