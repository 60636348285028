@use "../../scss/colors";
@use "../../scss/font";
@use "../../scss/mixins";

.badgeLegend {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;

  @include mixins.md_s {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 0.5rem;
    width: 80%;
    margin: auto;
  }

  &__container {
    display: flex;
    align-items: center;
    font-size: font.$size--standard;
    color: colors.$amway_black;
    font-weight: font.$weight--regular;
    padding: 0 0.5rem;
    gap: 0.25rem;
    border-right: 1px solid colors.$medium_gray;

    @include mixins.md_s {
      padding: 0;
      border: none;
      font-size: font.$size--medium;
    }
  }

  &__first {
    padding-left: 0;
  }

  &__last {
    padding-right: 0;
    border-right: none;
  }
}
