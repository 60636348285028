.Growth-requirement {
  &__tile {
    display: flex;
    width: 100%;
    flex: 1;

    & > * {
      width: 100%;
    }
  }

  &__tile-loader {
    width: 100%;

    .leaf-tile {
      height: 21rem;
    }
  }
}
