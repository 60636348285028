@use "../../scss/colors";
@use "../../scss/mixins";
@use "../../scss/font";

.LeafIncentiveBannerTile {
  height: 100%;

  .leaf-tile {
    padding: 1.5rem;

    @include mixins.md {
      padding: 1rem;
    }
  }

  .leaf-color-block {
    gap: 0.5rem;
  }

  .leaf-learn-more-link {
    height: 3rem;
    padding: 0.375rem 2rem;
    width: 100%;
    line-height: 1.5rem;

    @include mixins.tablet_only {
      gap: 0.5rem;
      padding: 0.75rem 2rem;
    }

    @include mixins.less_than_tablet {
      height: 2.25rem;
      font-size: font.$size--medium;
    }
  }

  &__bannerContents {
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;

    @include mixins.less_than_desktop_inclusive {
      flex-direction: column;
      gap: 1.5rem;
    }

    &.compact {
      flex-direction: column;
      gap: 1.5rem;
    }
  }

  &__bonusInfo {
    max-width: 60%;
    flex: 2;
    color: colors.$amway_black;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @include mixins.less_than_desktop_inclusive {
      width: auto;
      max-width: 100%;
      margin: 0;
    }

    &.textOnly {
      max-width: 100%;
    }

    &.compact {
      width: auto;
      max-width: 100%;
      margin: 0;
    }
  }

  &__title {
    font-size: font.$size--large;
    font-weight: font.$weight--bold;
    line-height: 1.75rem;

    @include mixins.less_than_tablet {
      font-size: font.$size--big-s;
      line-height: 1.5rem;
    }
  }

  &__additional-desc,
  &__description {
    line-height: 1.5rem;
    font-size: font.$size--standard;
    font-weight: font.$weight--regular;
  }

  &__bonusDetail {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.5rem;

    @include mixins.less_than_desktop_inclusive {
      gap: 1.5rem;
    }

    @include mixins.less_than_tablet {
      width: auto;
    }

    &.compact {
      width: auto;
      margin: 0;
    }

    &.learnMoreLinkOnly {
      justify-content: center;
    }
  }

  &__learnMoreButton {
    margin-top: 0;

    @include mixins.less_than_tablet {
      height: 2.25rem;
    }
  }

  &__incentiveCap {
    color: colors.$dark_grey_on_white;
    font-weight: font.$weight--medium;
    font-size: font.$size--medium;
    padding: 0.5rem 0.125rem;
  }
}
