@use "../../../../common/scss/colors";
@use "../../../../common/scss/font";
@use "../../../../common/scss/mixins";

.MultiplierDetails {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin: 2rem 0 1rem;

  @include mixins.tablet_only {
    flex-direction: column;
    gap: 1.2rem;
    margin-top: 1rem;
  }

  @include mixins.less_than_tablet {
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
  }

  .divider-line {
    display: none;

    @include mixins.tablet_only {
      display: block;
    }

    @include mixins.md {
      display: block;
    }
  }

  .MultiplierDetail {
    &__description {
      display: none;
    }
  }
}
