@use "../../../common/scss/colors";
@use "../../../common/scss/font";
@use "../../../common/scss/mixins";

.FGGMultiplierBase {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  scroll-behavior: smooth;

  &__header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 1.5rem;
  }

  &__header-container h2 {
    margin-bottom: 0.25rem;
  }

  &__multiplier-section {
    width: 100%;

    div {
      display: flex;
      align-items: center;
    }

    span {
      margin-right: 0.5rem;
    }

    p {
      font-size: font.$size--big;
      font-weight: font.$weight--bold;
    }

    &__green {
      color: colors.$light_green;
    }
  }

  &__divider-line {
    height: 0.063rem;
    background-color: colors.$light_gray_accent;
    margin: 0.75rem 0;
    width: 100%;
  }

  &__total-container {
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: font.$weight--bold;
    margin-top: 1rem;

    p {
      font-size: font.$size--big;
      width: auto;
      width: 70%;
    }

    span {
      font-size: font.$size--big;
      position: relative;
      word-wrap: break-word;
      padding-left: 1.25rem;

      @include mixins.sm_xl {
        width: auto;
      }
    }
  }

  @include mixins.md_s {
    .leaf-modal__modal-container {
      height: auto;
    }
  }
}
