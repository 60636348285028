@use "../../../common/scss/colors";
@use "../../../common/scss/mixins";
@use "../../../common/scss/font";

%impersonationButton {
  cursor: pointer;
  box-sizing: border-box;
  border: none;
  height: 2rem;
  width: 2rem;
  padding: 1rem;
  display: flex;
  place-content: center;
  place-items: center;
  border-radius: 0.5rem;
}

.impersonationHistory {
  &__rowItem {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    margin-left: 1rem;

    @include mixins.md {
      margin-left: 0.5rem;
    }
  }

  &__buttonGroup {
    justify-items: flex-end;
    justify-content: flex-end;
    padding-right: 1rem;
    gap: 1rem;
  }

  &__impersonationButton {
    @extend %impersonationButton;

    svg,
    path,
    img {
      cursor: pointer;
    }

    &__deleteButton {
      @extend %impersonationButton;

      background: #d9534f;
      padding: 0.5rem;

      svg {
        cursor: pointer;
        display: flex;
        width: 2rem;

        path {
          fill: white;
        }
      }
    }

    &__impersonateButton {
      @extend %impersonationButton;

      background: colors.$blue;

      .icon-user--white {
        cursor: pointer;

        svg {
          scale: 50%;
          cursor: pointer;
        }
      }
    }
  }
}
