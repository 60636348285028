@use "../../scss/colors";

$badge_width: 2.5rem;
$badges_per_row: 4;

.badge-grid {
  &__badge-group {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem calc(calc(100% - ($badge_width * $badges_per_row)) / ($badges_per_row - 1));
    margin-bottom: 1rem;
    align-items: center;
  }

  &__shield {
    align-items: center;
    width: $badge_width;
    display: flex;
    flex-direction: column;
  }

  &__shield-month {
    font-size: 0.875rem;
    color: colors.$dark_grey_on_white;
  }

  &__section-title {
    width: 100%;
    font-size: 1.3125rem;
    font-weight: 500;
    margin-top: 1rem;
  }
}

.badge-legend {
  border-top: 1px solid colors.$light_gray;
  padding-top: 1rem;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  row-gap: 0.5rem;

  &__label {
    margin-left: 0.5rem;
    font-size: 0.875rem;
  }

  div {
    align-items: center;
    display: flex;
    flex-basis: 50%;
  }
}
