@use "../../scss/colors";
@use "../../scss/font";
@use "../../scss/mixins";

.Tracking {
  display: flex;
  align-content: space-between;
  flex-direction: column;
  height: 100%;

  &__header {
    font-weight: font.$weight--bold !important;
    font-size: font.$size--large;
    margin-top: 0.5rem;

    @include mixins.less_than_tablet {
      font-size: font.$size--big_s;
    }
  }

  &__sections {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1.8rem;
    margin-bottom: 1.2rem;

    @include mixins.less_than_tablet {
      flex-direction: column;
      gap: 0.75rem;

      .vertical-divider {
        display: none;
      }
    }

    .divider-line {
      display: none;

      @include mixins.less_than_tablet {
        display: block;
      }
    }
  }

  &__section {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;

    &-title {
      font-size: font.$size--standard;
      font-weight: font.$weight--regular;

      @include mixins.less_than_tablet {
        font-size: font.$size--medium;
      }
    }
  }

  &__icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    padding: 2rem 0;

    @include mixins.tablet_only {
      padding: 1rem 0;
    }

    @include mixins.less_than_tablet {
      padding: 1rem 0;
    }
  }

  &__subtext {
    font-size: font.$size--standard;
    font-weight: font.$weight--regular;
    line-height: 1.5rem;
    text-align: center;
  }

  &__bold {
    font-weight: font.$weight--bold;
    font-size: font.$size--standard;
    color: colors.$dark_purple;
  }

  &__met {
    color: colors.$light_green;
  }

  .LeafButton {
    margin-top: 1rem;
  }
}
