@use "../../../../common/scss/colors";
@use "../../../../common/scss/font";
@use "../../../../common/scss/mixins";

.bronze-consistency-payment-details {
  &__header {
    padding: 0 1rem 1rem !important;
    font-size: 1.3125rem !important;
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  &__schedule {
    display: flex;
    justify-content: space-between;
    background-color: colors.$amway_white;
    line-height: 2.5rem;
    font-weight: font.$weight--medium;
    font-size: font.$size--standard;
    padding: 0 1.25rem;

    @include mixins.less_than_tablet {
      font-size: font.$size--medium;
      line-height: 1.125rem;
      padding: 0.5rem 0.75rem;
    }

    &--met {
      background-color: colors.$white;
      color: colors.$light_green;
      font-weight: font.$weight--bold;
    }
  }

  &__target {
    text-align: left;
  }

  &__amount {
    text-align: right;
  }

  .leaf-modal__modal-container {
    height: auto;
    padding-bottom: 1rem;
  }
}
