@use "../../../scss/colors";
@use "../../../scss/font";
@use "../../../scss/mixins";

.BadgeReqPrevious {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  width: 100%;
  align-items: center;

  @include mixins.less_than_tablet {
    flex-direction: column;
  }

  @include mixins.tablet_only {
    flex-direction: column;
  }

  &__storybook {
    width: 100%;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 1.5rem;
    height: 100%;
    padding-bottom: 1rem;
    border: none;
  }

  &__desc {
    text-align: center;
    font-weight: font.$weight--regular;
    font-size: font.$size--standard;
    line-height: 1.25rem;
    color: colors.$dark_grey_on_white;
    height: 5rem;

    @include mixins.less_than_tablet {
      height: auto;
      min-height: 2rem;
    }

    @include mixins.tablet_only {
      height: auto;
      min-height: 2rem;
    }
  }

  &__phantomLabel {
    height: 1.5rem;
    width: 1.5rem;
  }

  &__compareHeading {
    font-weight: font.$weight--bold;
    margin-left: 0.5rem;
    font-size: font.$size--big;
    text-align: left;

    @include mixins.tablet_only {
      margin-left: 0;
      width: 100%;
    }

    @include mixins.less_than_tablet {
      margin-left: 0;
    }
  }

  &__compareYears {
    margin-top: 1.5rem;
    display: flex;
    align-items: stretch;

    @include mixins.less_than_tablet {
      flex-direction: column;
    }
  }

  &__compareYearsItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 2;
    flex-direction: column;
    width: 100%;
    text-align: center;
    font-weight: font.$weight--bold;
    font-size: font.$size--big;

    @include mixins.less_than_tablet {
      flex-direction: row;
      font-size: 1rem;
    }

    @include mixins.tablet_only {
      flex-direction: column;
    }

    &BottomText {
      font-weight: font.$weight--thin;
      font-size: font.$size--big_s;
      margin-top: 0.5rem;
      text-align: left;

      @include mixins.less_than_tablet {
        font-size: font.$size--big;
      }
    }
  }

  &__tileContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
  }

  &__container,
  &__bottomSection {
    width: 50%;
    box-sizing: border-box;
  }

  @include mixins.less_than_tablet {
    &__tileContainer {
      flex-direction: column;
    }

    .awardProgressBadge__title {
      font-size: font.$size--medium;
    }
  }
}

#BadgeReqPrevious__compareTile {
  width: 100%;
}

#BadgeReqPrevious__maintainQc {
  border: none;
  width: 100%;
}
