@use "../../common/scss/colors";
@use "../../common/scss/mixins";

.ttci {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: colors.$white;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__long-date {
    margin-right: 3rem;
    color: colors.$dark_grey_on_white;

    @include mixins.less_than_desktop_inclusive {
      display: none;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 2.5rem;
    height: 100%;

    @include mixins.less_than_desktop_inclusive {
      margin: 0 1rem;
    }
  }

  &__row-one {
    display: flex;
    justify-content: space-between;
    flex: 2;
  }

  &__non-ana-body {
    @include mixins.over__md_l {
      flex-direction: row !important;

      .ttci__row-one {
        margin-top: 2.4rem;
      }

      .ttci__qualify-title {
        margin-top: 0 !important;
      }
    }
  }

  &__banner-tile {
    flex: 2;
    width: 67%;

    @include mixins.md_l {
      width: 100%;
    }
  }

  &__coming-soon {
    margin-top: 0 !important;

    .ttci__banner-tile {
      flex: none;
    }
  }

  &__placeholder-tile {
    width: 32%;
    margin-left: 1rem;

    @include mixins.less_than_desktop_inclusive {
      display: none;
    }
  }

  &__section-title {
    margin: 1rem 0;
  }

  &__row-two {
    flex: 1;
  }

  &__row-two-tiles {
    display: flex;
    flex: 1;
    flex-direction: row;

    @include mixins.md_l {
      width: 100% !important;
      display: flex;
      flex-direction: column;
    }
  }

  &__non-ana-row-two {
    @include mixins.over__md_l {
      margin-left: 1rem;
    }
  }

  &__eligible-pin-tile {
    width: 100%;
    flex: 1;

    @include mixins.over__md_l {
      height: 100%;
      margin-right: 1rem;
    }
  }

  &__lower-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex: 2;

    @include mixins.md_s {
      flex-direction: column;
    }

    @include mixins.from_mobile_to_tablet_inclusive {
      margin-top: 1rem;
    }
  }

  &__culmulative-tile {
    width: 100%;

    @include mixins.md_s {
      margin-top: 1rem;
    }

    @include mixins.over__md_l {
      margin-right: 1rem;
    }

    @include mixins.from_mobile_to_tablet_inclusive {
      margin-right: 1rem;
    }
  }

  &__monthly-average-tile {
    width: 100%;

    @include mixins.md_s {
      margin-top: 1rem;
    }
  }

  &__footer {
    position: relative;
    bottom: 0;
    margin: 1rem 0 2rem;
  }
}

.hidden {
  display: none !important;
}

.ttci-message-box {
  display: flex;
  margin: 0.5rem 2rem;
}
