.ptbEligibilityTile {
  &__formatRemainingTimes {
    display: inline;
    width: 3.688rem;
    right: 1.25rem;
    color: #1e247f;
    font-size: 1rem;
    font-weight: 700;
  }
}

#ptbEligibilityButton {
  display: flex;
  justify-content: center;
  justify-self: center;
  margin: auto;
  margin-top: 1.5rem;
  width: 100%;
  height: 2.25rem;
}
