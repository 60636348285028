@use "../../scss/colors";
@use "../../scss/font";
@use "../../scss/mixins";

.LeafIncentiveLegCounts {
  &__container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex: 1 0 0;

    &__leg {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.75rem;
      align-self: stretch;
      justify-content: space-between;

      &__label {
        font-size: font.$size--big;
        font-weight: font.$weight--bold;
        text-align: center;

        @include mixins.sm_l {
          font-size: font.$size--standard;
        }

        &.true {
          color: colors.$amway_black;
        }

        &.false {
          color: colors.$dark_grey_on_white;
        }
      }

      &__icon {
        display: flex;
        justify-content: center;
        width: 3rem;
        height: 3rem;

        @include mixins.sm_l {
          width: 2rem;
          height: 2rem;
        }

        svg {
          height: 3rem;

          @include mixins.sm_l {
            height: 2rem;
          }
        }
      }
    }
  }
}
