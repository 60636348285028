@use "../../../../common/scss/font";
@use "../../../../common/scss/colors";

.two-badges-req-tile {
  .leaf-tile {
    &__title {
      line-height: 1.75rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    &--two-badges {
      gap: 2.5rem;
      display: flex;
      justify-content: center;
    }

    &__FQ,
    &__QC {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: font.$size--standard;
      font-weight: font.$weight--bold;
      line-height: 1.5rem;
      gap: 0.75rem;

      .icon-shield--small {
        height: 4rem;
        width: 4rem;
      }
    }

    &__separator {
      border: 0.063rem solid colors.$light_gray;
    }

    &--view-details-button {
      margin-top: 1.5rem;
      width: 100%;

      .leaf-filter-button {
        width: 100%;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        height: 2.25rem;
        justify-content: center;
        font-size: font.$size--standard;
        line-height: 1.5rem;
      }
    }
  }
}
