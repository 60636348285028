@use "../../../../scss/font";
@use "../../../../scss/colors";

.leaf-radio-button {
  &__label {
    display: flex;
    align-items: center;
    padding: 0.6875rem 0.6875rem 1rem 0;
    gap: 0.5rem;
    height: 2.625rem;
  }

  &__text {
    font-family: font.$family--primary;
    font-weight: font.$weight--medium;
    font-size: font.$size--standard;
    line-height: 1.5rem;
  }

  &__circle {
    height: 1.25rem;
    width: 1.25rem;
    border: 0.125rem solid;
    display: inline-block;
    right: 7.8125rem;
    border-radius: 50%;
  }

  &__circle::after {
    content: "";
    height: 0.5rem;
    width: 0.5rem;
    display: block;
    background: white;
    left: 50%;
    top: 50%;
    transform: translate(50%, 50%);
    border-radius: 50%;
    opacity: 0;
  }

  input {
    display: none;
  }

  input:checked ~ &__circle::after {
    opacity: 1;
  }

  input:checked ~ &__circle {
    background: colors.$blue;
    border-color: colors.$blue;
  }

  input:checked ~ &__text {
    color: colors.$blue;
  }
}
