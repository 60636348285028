@use "../../../../../common/scss/colors";
@use "../../../../../common/scss/font";
@use "../../../../../common/scss/breakpoints";

.MonthlyPGGPerformanceBonusModal {
  &__badge-cell-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    p {
      color: colors.$dark_grey_on_white;
      font-size: font.$size--medium;
      width: 1rem;
    }
  }

  .leaf-modal {
    &__modalHeader {
      text-align: center;
      margin-bottom: 1rem;
    }

    &__modal-subtitle {
      font-weight: font.$weight--bold;
    }

    @media (max-width: breakpoints.$sm_ml) {
      &__modal-content {
        padding: 0 0.5rem;
      }

      &__modal-body {
        overflow-y: auto;
      }
    }
  }
}
