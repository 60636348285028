@use "../../../common/scss/colors";
@use "../../../common/scss/font";
@use "../../../common/scss/mixins";

%toggleButton {
  border-radius: 6.25rem;
  font-family: font.$family--header;
  font-size: font.$size--standard;
  background: colors.$white;
  min-height: 2.25rem;
  height: max-content;
  place-items: center;
  justify-content: center;
  width: auto;
  padding: 0.3125rem 1rem;
  cursor: default;
  color: colors.$amway_black;
  font-weight: normal;
  border: 0.0625rem solid colors.$light_gray;
}

.vision-bonus {
  &__content {
    &__calculation {
      background-color: colors.$very_light_gray;
      padding-bottom: 1.5rem;
      margin: 0 -2.5rem;

      @include mixins.less_than_desktop_inclusive {
        margin: 0 -1.5rem;
      }

      &__container {
        display: flex;
        flex-direction: column;
        padding: 0 2.5rem;
        gap: 1.5rem;

        @include mixins.less_than_desktop_inclusive {
          padding: 0 1.5rem;
          gap: 0.75rem;
        }
      }

      &__header__container {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        padding-top: 3rem;

        @include mixins.less_than_desktop_inclusive {
          gap: 0.75rem;
          padding-top: 1.5rem;
        }

        &__header {
          font-size: font.$size--large;
          font-weight: font.$weight--bold;
          line-height: 1.75rem;

          @include mixins.less_than_tablet {
            font-size: font.$size--big_s;
          }
        }

        &__sub-header {
          color: colors.$dark_grey_on_white;
          line-height: 1.25rem;

          @include mixins.less_than_tablet {
            font-size: font.$size--medium;
            line-height: 1.125rem;
          }
        }
      }

      &__menu {
        display: flex;
        gap: 0.5rem;

        .leaf-filter-button {
          height: 2.25rem;
          padding: 0.3125rem 1rem;

          @extend %toggleButton;

          &:hover {
            cursor: pointer;
            border: 0.0625rem solid colors.$amway_black;
            font-weight: font.$weight--medium;
          }

          &--active {
            @extend %toggleButton;

            border: 0.125rem solid colors.$amway_black;
            padding: 0.3125rem 1rem;
            font-weight: font.$weight--bold;

            &:hover {
              cursor: auto;
              border: 0.125rem solid colors.$amway_black;
              padding: 0.3125rem 1rem;
              font-weight: font.$weight--bold;
            }
          }

          @include mixins.sm_xl {
            font-size: font.$size--medium;
          }
        }
      }

      &__content {
        display: flex;
        justify-content: space-between;
        gap: 1.5rem;

        @include mixins.less_than_tablet {
          flex-direction: column;
        }

        .leaf-tile {
          @include mixins.less_than_tablet {
            min-width: 100%;
          }
        }
      }
    }
  }
}
