@use "../../common/scss/colors";
@use "../../common/scss/font";
@use "../../common/scss/layers";

$periodSelectorHeight: 2.313rem;
$listItemHeight: 2.25rem;

.period-selector {
  height: 100%;

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__nav-items {
    height: $periodSelectorHeight;
    min-width: 6.25rem;
    color: colors.$black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &__selected-period {
    height: 100%;
    border: colors.$amway_black 0.17rem solid;
    border-radius: 0.625rem;

    &:hover {
      border: colors.$blue 0.17rem solid;
    }
  }

  &__period {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6.25rem;
    background: colors.$white;
    color: colors.$black;
    border-radius: 0.625rem;
    height: 100%;
    font-size: font.$size--medium;

    /* disable text highlighting */
    user-select: -moz-none;
    user-select: none;
    cursor: pointer !important;
    border-color: transparent;
  }

  &__list-container {
    box-sizing: border-box;
    margin-top: 0.125rem;
    right: calc(1.563rem + 1.25rem);
    scroll-behavior: smooth;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.25rem 0.5rem 0.25rem 0;
    justify-content: center;
    position: absolute;
    min-width: 12.5rem;
    max-width: 1.375rem;
    max-height: 11.75rem;
    z-index: layers.$dropdown;
    background-color: colors.$white;
    border: 0.125rem solid colors.$white;
    box-shadow: 0 0.125rem 1rem rgb(44 44 44 / 10%);
    border-radius: 0.313rem;

    &--hidden {
      display: none;
    }
  }

  &__list {
    width: 100%;
    outline: 0;
    border: 0;
    box-sizing: border-box;
    padding: 0 0.5rem 0 0;
    scroll-behavior: smooth;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden auto;
    scroll-snap-type: y mandatory;
    scroll-margin: $listItemHeight 0;
    max-height: $listItemHeight * 5;
    justify-content: center;
    border-radius: 0;
    color: colors.$black;
  }

  &__list-item,
  &__list-item--active {
    height: $listItemHeight;
    width: 100%;
    display: flex;
    text-align: left;
    align-items: center;
    padding-left: 0.5rem;
    font-size: font.$size--medium;
    scroll-snap-align: center;
    cursor: pointer !important;
  }

  &__list-item--active {
    font-weight: font.$weight--medium;
    font-size: font.$size--standard;
    color: colors.$blue;

    &:hover {
      background: colors.$light_purple;
    }
  }

  &__list-item:hover {
    color: colors.$blue;
    background: colors.$light_purple;
    font-weight: font.$weight--regular;
  }

  &__arrow {
    display: flex;
    width: 1.875rem;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  &__arrow-button {
    border-color: transparent;
    background-color: transparent;

    .icon-carrot__left,
    .icon-carrot__right {
      cursor: pointer;
    }

    .icon-carrot--disabled {
      cursor: default;
    }

    &:hover .icon-carrot {
      color: blue;
    }
  }

  &__backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgb(196 196 196 / 50%);
    top: 0;
    right: 0;

    &--invisible {
      background-color: transparent !important;
    }
  }
}
