@use "../../../common/scss/colors";
@use "../../../common/scss/font";
@use "../../../common/scss/mixins";

// calculate the width of the tile minus column gap
@function calctitlewidth($count, $gap) {
  @return calc(((1 / $count) * 100%) - calc(($gap * ($count - 1)) / $count));
}

@mixin tile-width($cols, $gap) {
  min-width: calctitlewidth($cols, $gap);
  max-width: calctitlewidth($cols, $gap);
}

$row_gap_lg: 1.5rem;
$row_gap_md: 0.5rem;

.ptb {
  height: auto;
  flex: 1;

  &__section-title {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    font-weight: 700;

    @include mixins.md_l {
      font-size: 1.125rem;
      margin-bottom: 1rem;
    }
  }

  &__exception,
  &__hidden {
    display: none !important;
  }

  &__error {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__long-date {
    margin-right: 3rem;
    color: colors.$dark_grey_on_white;

    @include mixins.less_than_desktop_inclusive {
      display: none;
    }
  }

  &__row {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    margin-bottom: 3rem;
    align-items: flex-start;
    gap: $row_gap_lg;

    @include mixins.md_l {
      margin-bottom: 1.625rem;
      gap: $row_gap_md;
    }
  }

  &__row > * {
    @include tile-width(3, $row_gap_lg);

    flex-shrink: 0;
    flex-basis: 0;

    /* make tile fill parent container */
    align-self: stretch;

    @include mixins.md_l {
      @include tile-width(2, $row_gap_md);
    }

    @include mixins.md_s {
      min-width: 100%;
    }
  }

  &__row-tile {
    &--hidden {
      display: none;
    }
  }

  &__row-tile > * {
    height: 100%;
  }

  &__requirements-row {
    min-height: 21.875rem;
  }

  &__new-legs {
    font-weight: font.$weight--bold;
    color: colors.$dark_blue;
  }

  &__overview {
    display: flex;
    gap: 1.5rem;
    padding-bottom: 1.5rem;
    align-items: stretch;

    @include mixins.less_than_desktop_inclusive {
      flex-direction: column;
    }

    &__banner,
    &__tracking,
    &__eligible {
      flex: 0 1 33.33%;
      min-width: 0%;
    }

    &__banner {
      &--single {
        @include mixins.more_than_desktop_inclusive {
          width: 60%;
        }
      }
    }

    &__eligible {
      &--hidden {
        display: none !important;
      }
    }
  }
}
