@use "../../scss/colors";
@use "../../scss/mixins";

.LeafTileDynamicDivider {
  flex-grow: 0;
  width: 1px;
  margin: 0 1rem;
  background-color: colors.$light_gray;
  height: auto;
  align-self: stretch;

  @include mixins.less_than_tablet {
    width: 100%;
    height: 1px;
    margin: 0.25rem 0;
    align-self: center;
  }
}
