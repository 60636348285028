@use "../../../../common/scss/colors";
@use "../../../../common/scss/mixins";

.bgt-table {
  display: flex;
  flex-direction: column;

  &__infinite-scroll {
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
  }

  &__row {
    display: flex;
    flex-direction: row;
    border: 0.0625rem solid colors.$light_gray;
    border-radius: 0.75rem;
    padding: 1rem 1.5rem;
    margin-bottom: 0.5rem;
  }

  &__row-list-item {
    border-radius: 0.75rem;
    list-style-type: none;
    outline-width: 0.0625rem;
    outline-color: colors.$blue;
  }

  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;

    &--reserved {
      width: 1rem;
    }
  }

  &__cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1.5rem;
    padding-right: 0.5rem;
    flex: 0 1 20%;
    min-width: 5rem;

    &--name {
      flex: 0 2 30%;
      min-width: 10rem;
    }

    &--tags {
      flex: 0 0 25%;
      min-width: 12rem;
    }

    &--progress {
      flex: 0 1 15%;
      min-width: 8rem;
    }

    &--eligibility {
      flex: 0 2 10%;
    }
  }

  &__header {
    background: colors.$amway_black;
    color: colors.$white;
    box-shadow: 0.125rem 0.125rem 0.25rem rgb(44 44 44 / 10%);
    border-radius: 0.75rem;
    margin-bottom: 0.5rem;
    padding: 0.8125rem 1.5rem;
    flex: 0 1 100%;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }

  &__header-sticky-wrapper {
    display: inline-flex;
    position: sticky;
    background: colors.$white;
    top: 0;
    height: 100%;
  }

  &__display-header {
    &--desktop {
      display: inline-flex;
      padding-top: 1.5rem;

      @include mixins.md_l {
        display: none;
      }
    }
  }
}

.bgtNoData {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8rem;
}
