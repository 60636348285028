.vcsRequirementTile {
  &__description {
    text-align: center;
  }

  &__tag {
    margin-bottom: 1rem;
    height: 1.313rem;
    text-align: center;
  }

  &__tagContent {
    font-weight: 800;
  }

  &__progressTracker {
    margin-bottom: 1.5rem;
  }

  &__leafHalfCircle {
    display: flex;
    justify-content: center;
  }

  &__content {
    height: 18.3rem;
  }

  .leaf-tile__children {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
