@use "../../../../../common/scss/colors";
@use "../../../../../common/scss/mixins";
@use "../../../../../common/scss/font";

.YtdTotalsModal-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  &__first-cell-container {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: font.$size--medium;
    padding: 0.375rem 0.75rem;
    gap: 0.625rem;

    @include mixins.less_than_tablet {
      gap: 0.125rem;
      padding: 0.25rem 0.75rem;
    }

    &--month {
      display: flex;
      font-size: font.$size--medium;
      color: colors.$dark_grey_on_white;
      min-width: 1.8rem;
      text-align: center;

      @include mixins.less_than_tablet {
        font-size: font.$size--small;
      }
    }
  }

  .leaf-modal__modalHeader h1,
  .leaf-modal__modal-subtitle {
    text-align: center !important;
  }

  .leaf-modal__modalHeader h1 {
    font-size: font.$size--big_s;
  }
}
