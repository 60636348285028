@use "../../../common/scss/colors";
@use "../../../common/scss/font";
@use "../../../common/scss/mixins";
@use "../../../common/scss/breakpoints";

.FQsComparisonTile {
  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    &--sideBySideView {
      display: flex;
      flex-direction: row;

      @include mixins.less_than_desktop_inclusive {
        flex-direction: column;
      }
    }
  }

  &__heading {
    display: flex;
    justify-content: center;
    font-size: 1.5rem;

    @include mixins.md_l {
      justify-content: flex-start;
    }

    &--forceLeftAlign {
      justify-content: flex-start;
    }
  }

  &__check {
    margin-right: 0.5rem;
    margin-top: 0.25rem;
  }

  &__totalFQs {
    margin-top: 1rem;
    padding-left: 1rem;
    display: flex;
    justify-content: center;
    gap: 0.8rem;
  }

  &__requirementDescription {
    margin: 1.5rem auto;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    text-align: center;
    color: colors.$amway_black;
    width: 95%;

    @include mixins.md_l {
      width: 100%;
      margin: 1.5rem 0;
      font-size: 0.875rem;
      text-align: left;
      justify-content: flex-start;
    }

    &--forceLeftAlign {
      text-align: left;
    }
  }

  &__compareHeading {
    font-weight: 700;
    margin-left: 0.5rem;
    font-size: 1.125rem;
    text-align: left;

    @include mixins.less_than_tablet {
      margin-left: 0;
      width: 100%;
    }
  }

  &__compareYears {
    margin-top: 1.5rem;
    display: flex;
    align-items: stretch;

    @include mixins.less_than_tablet {
      flex-direction: column;
    }
  }

  &__compareYearsItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 2;
    flex-direction: column;
    width: 100%;
    text-align: center;
    font-weight: 800;
    font-size: 1.125rem;

    @include mixins.less_than_tablet {
      flex-direction: row;
      font-size: 1rem;
    }

    &BottomText {
      font-weight: 100;
      font-size: 1.3125rem;
      margin-top: 0.5rem;

      @include mixins.less_than_tablet {
        font-size: 1.125rem;
      }
    }
  }
}
