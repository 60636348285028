@use "../../../scss/colors";
@use "../../../scss/mixins";

.LeafIncentiveLegCounts {
  .orange {
    border: none;
    background: none;
  }
}

.LeafLegsRequirement {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;

  &__exempt {
    text-align: center;
    color: colors.$dark_grey_on_white;
  }

  .leaf-filter-button {
    width: 100%;
    height: max-content;
    padding: 0.375rem 2rem;

    @include mixins.less_than_tablet {
      padding: 0.563rem 2rem;
    }
  }

  &__tag {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    min-height: 3rem;
    line-height: 1.3rem;

    &--hide {
      display: none;
    }
  }
}
