@use "../../common/scss/colors";
@use "../../common/scss/font";
@use "../../common/scss/mixins";

.Ppe {
  width: 100%;
  padding: 0;

  h2 {
    font-size: font.$size--large;

    @include mixins.less_than_tablet {
      font-size: font.$size--big_s;
    }
  }

  &__container-split {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__section-padding {
    padding: 0 2.5rem;

    @include mixins.tablet_only {
      padding: 0 1rem 1rem;
    }

    @include mixins.less_than_tablet {
      padding: 0 1rem 1rem;
    }
  }

  &__container-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    @include mixins.tablet_only {
      flex-direction: column;
      gap: 1.2rem;
    }

    @include mixins.less_than_tablet {
      flex-direction: column;
      gap: 1rem;
    }
  }

  &__banner {
    width: 65%;
    font-size: font.$size--standard;
    line-height: font.$size--large;
    color: colors.$amway_black;

    @include mixins.tablet_only {
      width: 100%;
    }

    @include mixins.less_than_tablet {
      width: 100%;
    }
  }

  &__tracking {
    width: 34%;
    text-align: left;

    @include mixins.tablet_only {
      width: 100%;
      text-align: center;
    }

    @include mixins.less_than_tablet {
      width: 100%;
      text-align: center;
    }
  }

  &__calculation {
    background-color: colors.$very_light_gray;
    margin: 1rem 0;
    padding-top: 3rem;
    padding-bottom: 1.5rem;

    .leaf-tile {
      background-color: colors.$white;
    }

    @include mixins.from_mobile_to_tablet_inclusive {
      padding-top: 1.5rem;
    }
  }

  &__multiplication {
    width: 100%;
    margin-top: 1.5rem;
  }

  &__requirements {
    padding-top: 2rem;
    padding-bottom: 1.5rem;

    &-content {
      margin-top: 1.5rem;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1.25rem;

      @include mixins.tablet_only {
        grid-template-columns: repeat(2, 1fr);
      }

      @include mixins.less_than_tablet {
        grid-template-columns: repeat(1, 1fr);
      }

      &--ruby {
        min-width: 13.75rem;
        grid-column: span 2;
        display: grid;

        @include mixins.less_than_tablet {
          grid-column: span 1;
        }
      }
    }
  }

  .BannerTile {
    height: 100%;
    min-height: 12rem;
  }
}
