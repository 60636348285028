@use "../../../../common/scss/font";
@use "../../../../common/scss/colors";

%leg-req-tile-bold-placeholder {
  font-weight: font.$weight--bold;
}

.leg-req-tile {
  &__bold {
    @extend %leg-req-tile-bold-placeholder;

    &--purple {
      @extend %leg-req-tile-bold-placeholder;

      color: colors.$dark_purple;
    }
  }

  &__desc-container {
    font-family: font.$family--header;
    font-style: normal;
    font-weight: font.$weight--regular;
    font-size: font.$size--standard;
    line-height: 1.5rem;
  }

  &__desc-two {
    margin-top: 0.5rem;
  }

  &__leaf-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 3rem;
    line-height: 1.3rem;
  }
}
