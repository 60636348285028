@use "../../scss/font";
@use "../../scss/colors";
@use "../../scss/mixins";

.search-container {
  display: flex;
  position: relative;

  &__input-icons {
    position: relative;
    width: 100%;
  }

  &__icon {
    position: absolute;
    top: 0.5rem;
    right: 0.5625rem;
    font-size: font.$size--big;
    font-style: normal;

    .icon-close {
      cursor: pointer;
    }
  }

  &__input-icons input {
    display: flex;
    height: 2.25rem;
    width: 100%;
    padding: 0.1875rem 0.75rem;
    border-radius: 0.75rem;
    border: 0.125rem solid colors.$amway_black;
    font-weight: 500;
    gap: 0.3125rem;
  }

  &__input-icons input:focus {
    border: 0.125rem solid colors.$blue;
  }

  &__input-icons input::placeholder {
    color: colors.$dark_grey_on_white;
    font-family: font.$family--primary;
    font-style: normal;
    font-weight: normal;
    font-size: font.$size--standard;
    top: 0.0625rem;
    position: relative;
  }

  &__cancel-button {
    display: none;

    @include mixins.md {
      display: flex;
      width: 3.0625rem;
      height: 1.25rem;
      font-family: font.$family--primary;
      font-style: normal;
      font-weight: 700;
      font-size: font.$size--medium;
      line-height: font.$size--big;
      color: colors.$amway_black;
      border-bottom: solid 0.125rem colors.$amway_black;
      position: relative;
      top: 0.5rem;
      left: 1rem;
    }
  }

  @include mixins.md {
    flex-grow: 1;
    transition: all 0.5s;

    &--focused {
      flex: 1;
      max-width: 95%;
    }
  }
}
