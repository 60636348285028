@use "../../scss/colors";

%icon-lightning {
  display: block;
  width: 1rem;
  height: 1.125rem;
}

.icon-lightning {
  @extend %icon-lightning;

  &--blue {
    @extend %icon-lightning;
  }

  &--white {
    @extend %icon-lightning;

    svg,
    path {
      fill: colors.$white;
    }
  }
}
