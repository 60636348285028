@use "../../scss/colors";
@use "../../scss/mixins";
@use "../../scss/font";

.BannerTile {
  .leaf-tile {
    padding: 1.5rem;

    @include mixins.md {
      padding: 1rem;
    }
  }

  .leaf-color-block {
    gap: 0.5rem;
  }

  &__bannerContents {
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;

    @include mixins.sm_xl {
      flex-direction: column;
    }
  }

  &__bonusInfo {
    max-width: 44%;
    flex: 1;
    color: colors.$amway_black;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @include mixins.sm_xl {
      width: auto;
      max-width: 100%;
      margin: 0;
    }
  }

  &__title {
    font-size: font.$size--large;
    font-weight: font.$weight--bold;

    @include mixins.sm_xl {
      font-size: font.$size--big_m;
    }
  }

  &__description {
    line-height: 1.5rem;
    font-size: font.$size--standard;
  }

  &__bonusDetail {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include mixins.sm_xl {
      width: auto;
      margin: 1rem 0;
    }
  }

  &__learnMoreButton {
    margin-top: 1rem;
  }

  &__incentiveCap {
    color: colors.$dark_grey_on_white;
    font-weight: font.$weight--medium;
    font-size: font.$size--medium;
    padding: 0.5rem 0.125rem;
  }
}
