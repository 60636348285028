@use "../../../../common/scss/colors";

.bronzeProfileTab {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  &__sectionTitle {
    color: colors.$amway_black;
    font-size: 1rem !important;
    font-weight: 700;
    line-height: 1.5rem;
    padding: 0.25rem 0 !important;
  }

  &__sectionDescription {
    color: colors.$dark_grey_on_white;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem;
  }

  &--hidden {
    display: none;
  }

  &__sectionBody {
    margin-top: 1rem;
  }

  &__tags {
    display: flex;
    gap: 0.65rem 0.31rem;
    flex-wrap: wrap;
  }
}
