@use "../../scss/font";
@use "../../scss/colors";

.leafProgressBar {
  width: 100%;
  max-width: 20rem;
  margin: auto;

  &__current {
    display: block;
    font-size: font.$size--large_l;
    font-weight: font.$weight--bold;
    line-height: 2.25rem;
    text-align: center;
  }

  &__target {
    display: block;
    font-size: font.$size--medium;
    font-weight: font.$weight--medium;
    line-height: 0.875rem;
    text-align: center;
  }

  &__progress {
    margin-top: 1.5rem;
  }

  &__progress-bar {
    background-color: colors.$light_gray;
    border-radius: 0.25rem;
    height: 1.0625rem;
  }

  &__progress-filled {
    background-color: colors.$data_viz_orange;
    height: 100%;
    border-radius: 0.25rem;
    max-width: 100%;
  }

  &__progress-filled--met {
    height: 100%;
    border-radius: 0.25rem;
    max-width: 100%;
    background-color: colors.$light_green;
  }
}
