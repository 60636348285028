@use "./colors";
@use "./font";
@use "./mixins";

@mixin baseLayout {
  width: 100%;
  padding: 0;

  h2 {
    font-size: font.$size--large;

    @include mixins.less_than_tablet {
      font-size: font.$size--big_s;
    }
  }

  .Tracking {
    &__header {
      text-align: center;
    }
  }

  &__heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    &--title {
      display: flex;
      justify-items: flex-start;
      flex-direction: row;
    }

    &--timestamp {
      color: colors.$dark_grey_on_white;
      display: flex;
      align-items: center;
      padding-right: 2.5rem;

      @include mixins.less_than_desktop_inclusive {
        display: none;
      }
    }
  }

  &__container,
  &__container-split {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__section-padding {
    padding: 0 2.5rem;

    @include mixins.tablet_only {
      padding: 0 1rem 1rem;
    }

    @include mixins.less_than_tablet {
      padding: 0 1rem 1rem;
    }
  }

  &__container-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    @include mixins.tablet_only {
      flex-direction: column;
      gap: 1.2rem;
    }

    @include mixins.less_than_tablet {
      flex-direction: column;
      gap: 1rem;
    }
  }

  &__banner {
    width: 65%;
    font-size: font.$size--standard;
    line-height: font.$size--large;
    color: colors.$amway_black;

    @include mixins.tablet_only {
      width: 100%;
    }

    @include mixins.md {
      width: 100%;
    }
  }

  &__banner-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include mixins.md {
      flex-direction: column;
      gap: 1rem;
    }
  }

  &__banner-column {
    width: 49%;

    p {
      padding-top: 0.4rem;
    }

    .leaf-filter-button {
      width: 100%;
    }

    @include mixins.md {
      width: 100%;
    }
  }

  &__earnings-container {
    flex-direction: column;
  }

  &__potential-earnings {
    background-color: colors.$background_purple;
    color: colors.$dark_purple;
    border-radius: 0.4rem;
    padding: 1rem;
    margin-bottom: 2rem;
  }

  &__tracking {
    width: 34%;
    text-align: left;

    @include mixins.tablet_only {
      width: 100%;
      text-align: center;
    }

    @include mixins.md_m {
      width: 100%;
      text-align: center;
    }
  }

  &__calculation {
    background-color: colors.$very_light_gray;
    margin: 1rem 0;
    padding-top: 3rem;
    padding-bottom: 1.5rem;

    .leaf-tile {
      background-color: colors.$white;
    }

    @include mixins.from_mobile_to_tablet_inclusive {
      padding-top: 1.5rem;
    }
  }

  &__multiplication {
    width: 100%;
    margin-top: 1.5rem;
  }

  &__collapse-section {
    text-align: center;
    padding-bottom: 1rem;
    font-size: font.$size--standard;
    font-weight: font.$weight--bold;
    color: colors.$blue;
  }

  &__multiplier-sign {
    font-size: font.$size--large;
    font-weight: font.$weight--bold;

    @include mixins.tablet_only {
      font-weight: normal;
    }

    @include mixins.md {
      display: none;
    }
  }

  &__details-content {
    margin: 2rem 0 1rem;

    .divider-line {
      display: none;
    }

    @include mixins.tablet_only {
      margin-top: 1rem;

      .divider-line {
        display: block;
      }
    }

    @include mixins.md {
      margin-top: 1rem;

      .divider-line {
        display: block;
      }
    }
  }

  &__requirements {
    padding-top: 2rem;
    padding-bottom: 1.5rem;

    &-content {
      margin-top: 1.5rem;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-row: auto;
      gap: 1.25rem;

      @include mixins.tablet_only {
        grid-template-columns: repeat(2, 1fr);
      }

      @include mixins.md {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  &__requirement-tile {
    display: flex;
    width: 100%;
    flex: 1;

    & > * {
      width: 100%;
    }
  }

  &__requirement-tile-loader {
    .leaf-tile {
      height: 21rem;
    }
  }
  @content;
}
