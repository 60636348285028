@use "../../../common/scss/colors";
@use "../../../common/scss/font";
@use "../../../common/scss/mixins";

.TrackingProgressTile {
  height: 100%;

  &__icons {
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;

    .icon-checkCircle--lg svg {
      height: 3rem;
      width: 3rem;
    }
  }

  .Tracking {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-bottom: 0.4rem;
    align-items: center;

    &__header {
      text-align: center;
      margin-top: 0;
      padding-bottom: 0;
    }

    &__subtext {
      display: flex;
      justify-content: center;
    }

    .LeafButton {
      text-align: center;
      max-width: 25rem;

      @include mixins.tablet_only {
        font-size: font.$size--standard;
      }

      @include mixins.under_md_ms {
        font-size: font.$size--medium;
      }
    }
  }
}
