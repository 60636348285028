@use "../../../../common/scss/colors";
@use "../../../../common/scss/font";
@use "../../../../common/scss/mixins";

.MultiplierDetails {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin: 2rem 0 1rem;

  @include mixins.tablet_only {
    flex-direction: column;
    gap: 1.2rem;
  }

  @include mixins.less_than_tablet {
    flex-direction: column;
    gap: 1rem;
  }

  .divider-line {
    display: none;
  }

  @include mixins.tablet_only {
    margin-top: 1rem;

    .divider-line {
      display: block;
    }
  }

  @include mixins.md {
    margin-top: 1rem;

    .divider-line {
      display: block;
    }
  }

  &__section {
    width: 32%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mixins.tablet_only {
      width: 100%;
    }

    @include mixins.md_m {
      width: 100%;
    }

    &__title {
      font-size: font.$size--large;
      font-weight: font.$weight--bold;
      padding-bottom: 0.25rem;
      height: max-content;
      text-align: center;
    }

    &__subtitle {
      padding-bottom: 0.75rem;
      font-size: font.$size--big;
      font-weight: font.$weight--bold;
      line-height: font.$size--big_m;
      color: colors.$blue;
      text-align: center;

      @include mixins.tablet_only {
        padding-bottom: 0.3rem;
      }

      @include mixins.md_m {
        padding-bottom: 0.3rem;
      }
    }

    &__description {
      font-size: font.$size--medium;
      height: 2.625rem;
      margin-bottom: 0.75rem;
      text-align: center;

      @include mixins.tablet_only {
        height: fit-content;
        line-height: 1.5rem;
      }

      @include mixins.md_m {
        height: fit-content;
        line-height: 1.2rem;
      }
    }

    &__button {
      background-color: transparent;
      min-width: 16.125rem;
      border: 0.125rem solid colors.$amway_black;
      border-radius: 3.125rem;
      padding: 0.5rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: GTWalsheimPro, sans-serif;
      font-weight: font.$weight--bold;
      font-size: font.$size--standard;
      box-sizing: border-box;

      @include mixins.tablet_only {
        font-size: font.$size--medium;
      }

      @include mixins.md_m {
        font-size: font.$size--medium;
        min-height: 2.75rem;
      }
    }

    &__button-icon {
      margin-left: 0.5rem;
      cursor: pointer;

      span {
        cursor: pointer;
      }
    }

    &__button-text {
      cursor: pointer;
    }
  }

  &__first-cell-container {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 0.375rem 0.75rem;
    gap: 0.625rem;

    @include mixins.less_than_tablet {
      gap: 0.125rem;
      padding: 0.25rem 0.75rem;
    }

    &--month {
      display: flex;
      font-size: font.$size--medium;
      color: colors.$dark_grey_on_white;
      min-width: 1.8rem;
      text-align: center;

      @include mixins.less_than_tablet {
        font-size: font.$size--small;
      }
    }
  }

  &__mobile-font {
    @include mixins.less_than_tablet {
      font-size: font.$size--small;
    }
  }

  &__booster-cell {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
}
