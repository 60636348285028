@use "../../../common/scss/colors";
@use "../../../common/scss/font";
@use "../../../common/scss/mixins";

.ttci-banner-container {
  display: flex;
  justify-content: space-between;

  @include mixins.sm_xl {
    flex-direction: column;
  }

  &__section-one {
    width: 100%;
    margin: 0 1rem;
    color: colors.$amway_black;

    @include mixins.sm_xl {
      width: auto;
    }
  }

  &__title {
    font-size: font.$size--big_m;
  }

  &__section-two {
    width: 100%;
    margin: 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include mixins.sm_xl {
      width: auto;
      margin: 1rem;
    }
  }

  &__btn {
    margin-top: 1rem;
  }

  &__newyear-banner {
    font-weight: font.$weight--bold;
  }
}

.bold-banner-text {
  color: colors.$dark_purple;
  font-weight: font.$weight--bold;
}

.banner-message-box {
  margin: 0.5rem 0;
}
