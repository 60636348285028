@use "../../../common/scss/mixins";

.tracking-content {
  padding-bottom: 2rem;

  @include mixins.less_than_tablet {
    padding-right: 1rem;
  }

  &__search-features {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;

    @include mixins.less_than_tablet {
      flex-direction: column;
    }
  }

  &__icons-and-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 35%;

    @include mixins.less_than_desktop_inclusive {
      width: 45%;
    }

    @include mixins.less_than_tablet {
      width: 100%;
    }
  }

  &__filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-right: 1rem;
  }

  &__filter-bage-count {
    margin: 0 1rem;
  }

  &__search {
    width: 100%;
  }

  &__icon-filter {
    margin-right: 0.2rem;
  }

  &__filter-container {
    .leaf-panel__body {
      padding: 0;
    }

    .leaf-panel__right {
      top: 0;
    }

    .leaf-filter__header-title {
      display: none;
    }
  }

  &__sort-button {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    @include mixins.less_than_tablet {
      justify-content: flex-start;
    }
  }
}
