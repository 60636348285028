@use "../../../../common/scss/colors";
@use "../../../../common/scss/mixins";
@use "../../../../common/scss/font";

.ImpersonationTable {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  th {
    padding-left: 1rem;
    text-align: left;
  }

  &__container {
    display: table;
    width: 100%;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    overflow: hidden;
  }

  &__header {
    background: colors.$amway_black;
    color: colors.$white;
    font-weight: font.$weight--bold;
    font-size: font.$size--standard;
    height: 3rem;

    &__hidden {
      display: none;
    }

    @include mixins.md {
      font-size: font.$size--medium;
      padding: 1rem 0.5rem;
      word-break: break-word;
    }
  }

  &__bodyContainer {
    max-height: 26rem;
    overflow: auto;
  }

  &__row {
    height: 3rem;
    font-size: font.$size--standard;

    @include mixins.md {
      font-size: font.$size--medium;
    }
  }

  &__row:nth-child(odd) {
    background: colors.$amway_white;
  }

  &__row:hover {
    background: colors.$light_blue;
  }
}
