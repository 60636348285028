@use "../../../common/scss/colors";
@use "../../../common/scss/font";
@use "../../../common/scss/mixins";

.PQModal {
  text-align: center;

  .leaf-table {
    @include mixins.less_than_tablet {
      &__row {
        height: 2.625rem !important;
      }
    }

    th {
      min-width: 7.5rem;
    }
  }

  .legend {
    display: flex;

    @include mixins.md_s {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 0.5rem;
    }
  }

  &__body {
    overflow: auto;
    max-height: 20rem;
  }

  &__loading {
    padding-top: 0.5rem;
    height: auto;
  }

  .leaf-modal {
    &__modal-container {
      height: auto;
      max-height: max-content;
    }

    &__title {
      font-size: 1.3125rem;
      line-height: font.$size--large;
      font-weight: font.$weight--bold;
    }

    &__modal-desc {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: font.$weight--bold;
      font-size: font.$size--big !important;
      color: colors.$blue;
    }

    .container {
      display: flex;
      align-items: center;
      font-size: font.$size--standard;
      color: colors.$amway_black;
      font-weight: font.$weight--regular;
      padding: 0 0.5rem;
      gap: 0.25rem;
      border-right: 1px solid colors.$medium_gray;

      @include mixins.md_s {
        padding: 0;
        border: none;
        font-size: font.$size--small;
      }
    }

    .first {
      padding-left: 0;
    }

    .last {
      padding-right: 0;
      border-right: none;
    }
  }
}
