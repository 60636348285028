@use "../../../common/scss/colors";
@use "../../../common/scss/mixins";
@use "../../../common/scss/font";

.bronze-builder {
  &__overview {
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
    padding-bottom: 1.5rem;

    @include mixins.less_than_desktop_inclusive {
      flex-direction: column;
    }

    &__banner,
    &__tracking,
    &__eligible {
      flex: 1;
    }
  }

  &__incentive-calculation {
    background-color: colors.$very_light_gray;
    padding-bottom: 1.5rem;
    margin-left: -2.5rem;
    margin-right: -2.5rem;

    @include mixins.less_than_desktop_inclusive {
      margin-left: -1.5rem;
      margin-right: -1.5rem;
    }
  }

  &__banner-only {
    .bronze-builder__overview {
      &__tracking,
      &__eligible {
        display: none;
      }

      @include mixins.more_than_desktop_inclusive {
        &__banner {
          .leaf-tile {
            width: 60%;
          }
        }
      }
    }

    .bronze-builder {
      &__incentive-calculation {
        display: none;
      }
    }
  }

  &__eligible-banner-only {
    .bronze-builder__overview {
      &__banner,
      &__tracking {
        display: none;
      }

      @include mixins.more_than_desktop_inclusive {
        &__eligible {
          .leaf-tile {
            width: 60%;
          }
        }
      }
    }

    .bronze-builder {
      &__incentive-calculation {
        display: none;
      }
    }
  }
}
