@use "../../../common/scss/colors";
@use "../../../common/scss/font";
@use "../../../common/scss/mixins";

.miniProfile {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__aboInfo {
    display: flex;
    height: auto;
    flex-direction: column;
    padding: 1.5rem 1rem;
    align-items: flex-start;
    background: colors.$amway_white;
    border-bottom: 1px solid colors.$light_gray;
  }

  &__aboName {
    color: colors.$amway_black;
    font-size: font.$size--large;
    font-weight: font.$weight--bold;
    line-height: font.$size--huge;
  }

  &__aboNum {
    color: colors.$amway_black;
    font-size: font.$size--standard;
    font-weight: font.$weight--regular;
    line-height: font.$size--large;
  }

  &__contactInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: font.$size--medium;
    line-height: font.$size--big;
    gap: 0.25rem;
  }

  &__email {
    word-break: break-word;
  }

  &__aboGradDate {
    color: colors.$dark_grey_on_white;
    margin-top: 1rem;
  }

  #bgtMiniProfileViewInLOSButton {
    margin: auto;
    width: 72%;
    max-width: 21.5rem;
  }

  &__body {
    display: flex;
    padding: 0.5rem 1.5rem;
    flex: 2 0;
  }

  &__footer {
    display: flex;
    width: 100%;
    height: auto;
    padding: 2rem 0;
  }

  &__innerWrapper {
    display: flex;
    flex-direction: column;
    flex: 1 0;
    height: 100%;
    width: 100%;
    place-content: space-between space-between;
  }
}

.BGT-mini-profile-modal {
  .miniProfile__body {
    display: block;
  }

  .leaf-panel {
    &__body {
      padding: 0;
      height: 93%;
      min-height: 93%;
    }

    &__header {
      height: 3.5rem;
      margin-top: 0;
    }

    &__right,
    &__fullsize {
      overflow-y: hidden !important;

      @include mixins.md_s {
        height: 100% !important;
        max-height: 100% !important;
        border-radius: 0 !important;
      }
    }
  }

  .leaf-tab-views__header {
    width: 100%;
    white-space: nowrap;
  }

  .leaf-tab-views {
    flex: 1;
  }
}
