@use "../../../../common/scss/mixins";

.Tracking {
  &__header {
    text-align: center;
  }

  &__subtext {
    @include mixins.tablet_only {
      text-align: center;
    }
  }
}
