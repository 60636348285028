@use "../../scss/colors";
@use "../../scss/font";
@use "../../scss/mixins";
@use "../../scss/layers";

.kpi-selector {
  background-color: colors.$white;

  @include mixins.larger_than_mobile {
    position: absolute;
    z-index: layers.$modal;
    right: 0;
    width: 21.5rem;
    box-shadow: 0 0.13rem 1rem 0 colors.$amway_black;
    border-radius: 0.75rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.0625rem solid colors.$light_gray;
  }

  &__title {
    font-size: 1.3125rem;
    line-height: font.$size--large;
    font-weight: font.$weight--medium;
    padding: 1rem 0.625rem 0.625rem 1.562rem;
    word-wrap: break-word;
  }

  &__list {
    max-height: 29rem;
    overflow-y: auto;
    padding: 0.625rem 1.5625rem;

    @include mixins.sm_xl {
      padding: 0.625rem 1rem;
    }

    li {
      list-style-type: none;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    padding: 1.56rem;
  }

  &__cancel,
  &__apply {
    font-weight: font.$weight--bold;
    padding: 0.375rem 2rem;
    border: 0.125rem solid colors.$amway_black;
    border-radius: 6.25rem;

    @include mixins.under_md_ms {
      padding: 0.563rem 2rem;
      width: 10.375rem;
    }
  }

  &__cancel {
    margin-right: 0.625rem;
    background-color: colors.$white;
    color: colors.$amway_black;
  }

  &__apply {
    margin-left: 0.625rem;
    color: colors.$white;
    background-color: colors.$amway_black;
  }

  &__apply:disabled {
    background-color: colors.$disabled;
    color: colors.$dark_grey_on_white;
    border: 0.125rem solid colors.$disabled;
  }
}

.kpi-tracking-filter-modal {
  height: auto;
  top: auto;
  border-radius: 1.25rem;

  .leaf-panel__footer {
    position: relative;

    @include mixins.md_s {
      display: flex;
    }

    @include mixins.sm_m {
      padding: 0;
    }
  }

  .leaf-panel__backdrop {
    @include mixins.md_s {
      width: 100%;
      height: 100%;
    }
  }
}
