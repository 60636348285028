@use "../../../common/scss/colors";
@use "../../../common/scss/font";
@use "../../../common/scss/mixins";

.LSRequirements {
  .leaf-tile__title {
    align-items: flex-start;

    span {
      margin-top: 0.25rem;
    }
  }

  padding-top: 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row: auto;
  gap: 1.25rem;

  @include mixins.md_l {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @include mixins.md_s {
    display: flex;
    flex-direction: column;
  }

  .FQsComparisonTile {
    grid-column: span 2;
  }
}
