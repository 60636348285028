@use "../../scss/font";
@use "../../scss/colors";
@use "../../scss/mixins";

.Bbt {
  &__section-title {
    margin-bottom: 0.2rem;

    h2 {
      font-size: font.$size--large;
    }

    @include mixins.less_than_desktop_inclusive {
      margin-bottom: 0;
    }
  }

  &__section-description {
    font-size: font.$size--standard;
    line-height: font.$size--large;
    color: colors.$dark_grey_on_white;
  }

  &__section-title-ref,
  &__section-description-ref {
    vertical-align: super;
  }

  &__section-title-ref {
    font-size: font.$size--medium;
  }

  &__section-description-ref {
    font-size: font.$size--small;
  }

  &__row,
  &__section-subtitle,
  &__total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.67rem 0;
    line-height: font.$size--huge;
  }

  &__section-subtitle {
    font-size: font.$size--standard;
    font-weight: bold;
    padding: 0.8rem 0;
  }

  &__total-amount {
    font-size: font.$size--large;

    @include mixins.less_than_tablet {
      font-size: font.$size--standard;
    }

    @include mixins.sm_l {
      font-size: font.$size--medium;
    }
  }

  &__total {
    font-size: font.$size--big;
    font-weight: bold;
    padding-bottom: 0;

    @include mixins.less_than_tablet {
      font-size: font.$size--standard;
    }

    @include mixins.sm_l {
      font-size: font.$size--medium;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    font-size: font.$size--standard;
    padding-left: 1.25rem;
    padding-right: 1.25rem;

    a:link,
    a:visited {
      font-weight: bold;
      color: colors.$blue;
      text-decoration: underline;
      text-underline-offset: 0.3rem;
      text-decoration-thickness: 2px;
    }

    a:hover {
      font-weight: font.$weight--bold;
      color: colors.$dark_purple_2;
      text-decoration: underline;
      text-underline-offset: 0.3rem;
      text-decoration-thickness: 2px;
    }

    .icon-subsection {
      margin-left: 1.4rem;
      width: 1.5rem;
      background-position-y: 2px;
    }

    &__subsection {
      display: flex;
      align-items: center;

      .icon-plain-table {
        margin-left: 1.25rem;
        margin-right: 0.625rem;
        background-position: center;
      }

      &-amout {
        font-weight: font.$weight--regular;

        .icon-subsection {
          transform: rotateY(180deg);
          margin-left: 0;
        }
      }
    }

    &__btn {
      padding: 0 8px 1px 0;
      border-radius: 5px;
      font-size: 1rem;
      font-weight: bold;
      color: colors.$blue;
      text-decoration: underline;
      text-underline-offset: 0.27rem;
      text-decoration-thickness: 2px;
      background: none;
      border: hidden;
      outline: none;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        font-weight: 700;
        color: colors.$dark_purple_2;
        text-decoration: underline;
        text-underline-offset: 0.3rem;
        text-decoration-thickness: 2px;
      }

      &:focus {
        border: solid;
      }

      .icon-carrot__right {
        margin-left: 0.5rem;

        &:hover {
          color: colors.$dark_purple_2;
        }
      }
    }

    @include mixins.less_than_desktop_inclusive {
      padding: 0.4rem 0.75rem;
    }

    @include mixins.less_than_tablet {
      font-size: font.$size--medium;
    }
  }

  &__dark {
    background-color: colors.$amway_white;
  }

  &__bold {
    font-weight: bold;
  }
}
