@use "../../../../common/scss/colors";
@use "../../../../common/scss/mixins";

.MonthlyIncentiveCalculation {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  margin-top: 1.5rem;

  @include mixins.less_than_lg {
    flex-direction: column !important;
  }

  .leaf-tile {
    background-color: colors.$white;
  }

  .leaf-tile.desc-only {
    min-width: 4rem;
    width: 100%;
    flex-grow: 1;
    height: auto;
  }

  .LeafTileValue {
    gap: 0.25rem;
  }

  &__symbol {
    display: flex;
    align-items: center;
  }

  @include mixins.less_than_tablet {
    gap: 1rem;

    .leaf-tile {
      border: none;
      flex: 1;
      width: 100%;
    }

    &__symbol {
      display: none;
    }
  }

  &__monthlyCalc,
  &__ytdCalc {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    width: 100%;

    @include mixins.less_than_tablet {
      border-radius: 0.75rem;
      border: 1px solid colors.$light_gray;
      flex-direction: column;
      gap: 0;
      background-color: colors.$white;
    }
  }

  &__ytdCalc {
    .LeafTileValue__symbol {
      display: none;
    }
  }

  &__divider {
    display: none;

    @include mixins.less_than_tablet {
      display: block;
      background-color: colors.$amway_black;
      height: 0.0625rem;
      margin: 0.35rem 0.75rem;
    }
  }
}
