@use "../../../../common/scss/colors";
@use "../../../../common/scss/font";
@use "../../../../common/scss/mixins";

.BronzeIncentivesNavigation {
  &__menu {
    padding: 0 2.5rem 1.5rem;
    display: flex;
    gap: 0.5rem;
    text-wrap: nowrap;
    overflow: scroll;
    scrollbar-width: none;
    list-style: none;

    @include mixins.less_than_desktop_inclusive {
      padding: 0 1rem 1.5rem;
    }

    .leaf-filter-button {
      height: 2.25rem;
      padding: 0.5rem 1rem;
      width: max-content;
      color: colors.$amway_black;

      &.inactive {
        border: 0.0625rem solid colors.$light_gray;
        font-weight: font.$weight--medium;

        &:hover {
          cursor: pointer;
          border: 0.0625rem solid colors.$amway_black;
          font-weight: font.$weight--medium;
        }
      }
    }
  }
}
