@use "../../../../../common/scss/colors";
@use "../../../../../common/scss/font";
@use "../../../../../common/scss/mixins";

.CurrentFQModal {
  text-align: center;

  &__body {
    max-height: 20rem;

    @include mixins.less_than_tablet {
      overflow: auto;
    }
  }

  &__loading {
    padding-top: 0.5rem;
    height: auto;
  }

  .leaf-modal {
    &__modal-container {
      height: auto;
      max-height: max-content;
    }

    &__title {
      font-size: 1.3125rem;
      line-height: font.$size--large;
      font-weight: font.$weight--bold;
    }

    &__modal-desc {
      padding-bottom: 1rem;
      font-weight: font.$weight--bold;
      font-size: font.$size--big !important;
      color: colors.$blue;
    }
  }
}
