@use "../../../common/scss/colors";
@use "../../../common/scss/font";

.no-pins-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
  color: colors.$amway_black;
  text-align: center;
  font-family: font.$family--header;

  &__title {
    font-weight: font.$weight--medium;
    font-size: font.$size--big_m;
    margin: 0.5rem 0;
  }

  &__body {
    font-size: font.$size--standard;
    line-height: font.$size--big_m;
  }
}
