@use "../../../common/scss/colors";
@use "../../../common/scss/mixins";
@use "../../../common/scss/font";

%hightlighRow {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: font.$size--big;
  line-height: 1.3125rem;

  @include mixins.md_s {
    font-size: font.$size--standard;
  }
}

.PPEMultiplierMatrix {
  .leaf-modal {
    &__modalHeader {
      text-align: center;
    }

    &__modal-body,
    &__modal-container {
      height: auto;
    }

    &__modal-container {
      padding-bottom: 2rem;
    }
  }

  &__table {
    margin-top: 1.5rem;
  }

  &__row {
    display: flex;
    font-size: font.$size--standard;
    line-height: 1.5rem;
    justify-content: center;

    @include mixins.md_s {
      font-size: font.$size--medium;
    }
  }

  &__hightlight {
    &--green {
      @extend %hightlighRow;

      color: colors.$light_green;
    }

    &--orange {
      @extend %hightlighRow;

      color: colors.$data_viz_orange;
    }
  }

  &__rubyPv {
    @include mixins.md_s {
      text-align: left;
      justify-content: flex-start;
    }
  }
}
