@use "../../scss/colors";
@use "../../scss/font";
@use "../../scss/mixins";
@use "../../scss/layers";

.leaf-group-filter {
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  z-index: layers.$panel;
  background-color: colors.$white;
  height: 100%;
  bottom: 0;

  &--displayed {
    transform: translateY(0%);
  }

  &--dismissed {
    transform: translateY(100%);
  }

  &__filter-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
  }

  &__header-title {
    padding-top: 0.2rem;
    font-weight: font.$weight--medium;
    font-size: 1.313rem;
    line-height: 1.5rem;

    &--hidden {
      display: none;
    }
  }

  &__clear-all-section {
    padding-top: 0.3rem;
    padding-right: 0.4rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;

    @include mixins.md_s {
      padding-top: 0.5rem;
      padding-right: 1rem;
    }
  }

  &__clear-all {
    font-weight: font.$weight--bold;
    font-size: font.$size--medium;
    line-height: 1.125rem;
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: colors.$black;
  }

  &__filter-title {
    padding-left: 1.5rem;
    font-weight: font.$weight--bold;
    font-size: font.$size--standard;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;

    @include mixins.md_s {
      font-size: font.$size--medium;
    }

    &--hidden {
      padding-left: 1.5rem;
      font-weight: font.$weight--bold;
      font-size: font.$size--standard;
      line-height: 1.5rem;
      margin-bottom: 0.5rem;
      display: none;

      @include mixins.md_s {
        font-size: font.$size--medium;
      }
    }
  }

  &__filter-tabs {
    padding-top: 1.7rem;
    background-color: colors.$amway_white;
    display: block;
    width: 30%;
  }

  &__filter-title-mobile {
    padding: 0.75rem;
    font-size: font.$size--standard;
    color: colors.$black;
    width: 100%;
    border: none;
    text-align: left;
    background-color: transparent;

    @include mixins.md_s {
      font-size: font.$size--medium;
      padding-left: 0.75rem;
    }

    &--enabled {
      font-weight: font.$weight--bold;
    }

    &--selected {
      padding: 0.75rem 1rem;
      font-weight: font.$weight--bold;
      font-size: font.$size--standard;
      background-color: colors.$white;
      width: 100%;
      border: none;
      text-align: left;
      color: colors.$black;

      @include mixins.md_s {
        font-size: font.$size--medium;
        padding-left: 0.75rem;
      }
    }
  }

  &__filter-body {
    display: flex;
    overflow-y: auto;
    flex-grow: 1;
  }

  &__filter-options {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    height: 100%;
    width: 70%;
  }

  &__filter-by {
    display: flex;
    flex-direction: column;

    &--hidden {
      display: none;
    }

    padding-top: 2rem;
    height: 100%;
    overflow: auto;
  }

  &__filter-list {
    padding-left: 1.5rem;
  }

  &__filter-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background: colors.$white;
    margin-top: auto;

    .leaf-group-filter__filter-tabs {
      @include mixins.md_s {
        display: none;
      }
    }
  }

  &__footer-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 70%;
    padding: 0.4rem;

    @include mixins.md_s {
      padding: 0.8rem 0.4rem;
      width: 98%;
    }

    button {
      margin: 0.2rem;
      cursor: pointer;
      padding: 0 1.5rem;

      @include mixins.md_s {
        padding: 0;
      }
    }
  }

  &__mobile-background {
    &--displayed {
      top: 0;
      left: 0;
      width: 100%; /* to cover the whole screen */
      height: 100%;
      z-index: layers.$backdrop;
    }
  }

  &__close-icon {
    border: none;
    background-color: colors.$white;
  }
}

.leaf-filter-backdrop {
  background: rgb(112 112 112 / 70%);
  position: fixed;
  z-index: layers.$backdrop;
  width: 100%;
  height: 100%;
}

.long-text {
  @include mixins.md_s {
    padding: 1rem 0.4rem 1.5rem;
  }

  button {
    padding: 0 1.2rem;

    @include mixins.md_s {
      padding: 0;
    }
  }
}

.longer-text {
  button {
    padding: 0 1rem;

    @include mixins.md_s {
      padding: 0;
    }
  }
}

.very-long-text {
  button {
    padding: 0 0.7rem;

    @include mixins.md_s {
      padding: 0;
    }
  }
}
