@use "../../../../common/scss/colors";

.eligible {
  &__card {
    padding: 1rem;
    border-radius: 0.75rem;
    border: 1px solid colors.$light_gray;
  }

  &__card-title {
    font-size: 1.3125rem;
    font-weight: 500;
    margin-bottom: 0.5rem;

    span {
      font-weight: 700;
      color: colors.$dark_purple;
    }
  }

  &__card-description {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }

  &__description {
    margin-top: 2.5rem;
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
}
