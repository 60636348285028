@use "../../../../common/scss/colors";
@use "../../../../common/scss/mixins";
@use "../../../../common/scss/font";

.bbi-consistency-payment-metric {
  &__content {
    padding-left: 2.5rem;

    @include mixins.less_than_desktop_inclusive {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  &__incentive-calculation {
    width: 100%;
    padding: 0 2.5rem 0 0;

    @include mixins.less_than_desktop_inclusive {
      padding: 0;
    }
  }
}
