@use "../../../../common/scss/colors";
@use "../../../../common/scss/mixins";

.PtbBannerTile {
  display: flex;

  &__formatBonusAmount {
    span {
      font-weight: 800;
      color: colors.$dark_purple;
      margin-bottom: 1rem;
    }
  }
}
