@use "../../../../common/scss/colors";
@use "../../../../common/scss/font";
@use "../../../../common/scss/mixins";

.MonthlyPGGBannerTile {
  height: 100%;

  &__not-qualified-banners-only {
    .BannerTile {
      @include mixins.md {
        width: 100%;
      }
    }
  }
}
