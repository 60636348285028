@use "../../scss/colors";
@use "../../scss/font";
@use "../../scss/mixins";

.leaf-incentive-eligibility-banner {
  height: 100%;

  .leaf-tile {
    padding: 1.5rem;
  }

  .leaf-tile__children {
    gap: 1.5rem;
    display: flex;
    flex-direction: column;

    @include mixins.more_than_desktop_inclusive {
      margin-bottom: 2.5rem;
    }
  }

  .leaf-filter-button {
    height: auto;
    min-height: 2.25rem;
    padding: 0.375rem 2rem;
    width: 100%;

    @include mixins.sm_xl {
      font-size: font.$size--medium;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__title {
    h2 {
      font-size: font.$size--large;

      @include mixins.sm_xl {
        font-size: font.$size--big_s;
      }
    }
  }

  &__text {
    &__count {
      font-weight: font.$weight--bold;
      color: colors.$dark_purple;
    }
  }
}
