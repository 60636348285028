@use "../../common/scss/colors";
@use "../../common/scss/font";
@use "../../common/scss/mixins";

.LeadershipSeminar {
  &--automaticallyApproved {
    .short-date {
      display: none;
    }
  }

  &__requirementsSection {
    padding: 0.75rem 2.5rem;

    @include mixins.md_s {
      padding: 1rem;
    }

    &--title {
      font-size: font.$size--large;
    }

    &--subtext {
      padding-top: 0.5rem;
      font-size: font.$size--standard;
      color: colors.$dark_grey_on_white;

      .Numbers__bold {
        font-weight: font.$weight--bold;
        color: colors.$dark_purple;
      }

      .Numbers__met {
        color: colors.$light_green;
      }
    }

    &--description {
      font-size: font.$size--standard;
      margin: 0.75rem 0 0.5rem;

      .Numbers__bold {
        font-weight: font.$weight--bold;
        color: colors.$dark_purple;
      }

      .Numbers__met {
        color: colors.$light_green;
      }
    }

    &--tileSection {
      padding-top: 1rem;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-row: auto;
      gap: 1.25rem;

      @include mixins.tablet_only {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }

      @include mixins.md_s {
        display: flex;
        flex-direction: column;
      }
    }

    &--tile {
      width: 100%;
    }

    &--pqTile {
      width: 100%;
      padding-top: 1rem;
    }
  }

  &__footer {
    .bonus-footer-container {
      padding: 0.75rem 2.5rem;

      @include mixins.md_s {
        padding: 1rem;
      }
    }
  }
}
