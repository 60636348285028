@use "../../../../common/scss/colors";
@use "../../../../common/scss/font";
@use "../../../../common/scss/mixins";

.YearToDateSection {
  height: 100%;
}

.YearToDate {
  margin: 0.063rem 0.375rem;

  .vertical-divider {
    background-color: #e4e4e4;
    width: 100%;
    height: 1px;
    margin: 0.5rem 0;

    @include mixins.tablet_only {
      width: 1px;
      align-self: stretch;
      height: auto;
      margin: 0;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.188rem;
    font-weight: font.$weight--bold;
    font-size: font.$size--large;

    button {
      border: none;
      background-color: transparent;
    }

    @include mixins.tablet_only {
      text-align: center;
    }

    &-placeholder {
      display: none;

      @include mixins.tablet_only {
        display: block;
        width: 2.5rem;
      }
    }
  }

  &__sections {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: left;
    margin-bottom: 2rem;

    @include mixins.tablet_only {
      width: 100%;
      flex-direction: row;
      justify-content: space-evenly;
      justify-content: center;
    }

    @include mixins.sm_l {
      margin-bottom: 1.188rem;
    }
  }

  &__section {
    display: flex;
    width: 100%;
    max-width: 100%;
    flex-direction: row;
    text-align: left;
    justify-content: space-between;

    @include mixins.tablet_only {
      text-align: center;
      flex-direction: column;
      width: 33%;
      max-width: 33%;
      height: 100%;
      align-items: stretch;
      align-content: space-between;
      padding: 0 0.25rem;
    }

    &-title {
      font-size: font.$size--big;
      font-weight: font.$weight--bold;
      color: colors.$blue;
      line-height: 1.313rem;
      margin-bottom: 0.25rem;
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      word-wrap: break-word;

      @include mixins.sm_l {
        display: flex;
        flex-direction: row;
        text-align: left;
        justify-content: space-between;
        margin-right: 0.2rem;
      }

      @include mixins.md_m {
        font-size: font.$size--standard;
        line-height: 1.5rem;
      }
    }

    &-number {
      font-size: font.$size--big_s;
      line-height: 1.5rem;

      @include mixins.md_m {
        font-size: font.$size--big;
        line-height: 1.313rem;
      }
    }

    @include mixins.tablet_only {
      width: 11rem;
    }
  }

  &__button-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__button {
    background-color: transparent;
    min-width: 16.125rem;
    border: 0.125rem solid colors.$amway_black;
    border-radius: 3.125rem;
    padding: 0.75rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: GTWalsheimPro, sans-serif;
    font-weight: font.$weight--bold;
    font-size: font.$size--standard;
    box-sizing: border-box;
    color: colors.$amway_black;
    width: 100%;

    @include mixins.tablet_only {
      width: fit-content;
      font-size: font.$size--medium;
      padding: 0.563rem;
    }

    @include mixins.md_m {
      font-size: font.$size--medium;
      padding: 0.563rem;
    }
  }
}
