@use "../../common/scss/colors";
@use "../../common/scss/font";
@use "../../common/scss/mixins";

.bronze-incentives {
  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__viwes {
    padding-left: 2.5rem;
    padding-right: 2.5rem;

    @include mixins.less_than_desktop_inclusive {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  &__date {
    color: colors.$dark_grey_on_white;
    align-content: center;
    padding-right: 2.5rem;

    @include mixins.less_than_desktop_inclusive {
      display: none;
    }
  }
}
