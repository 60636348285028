@use "../../../../common/scss/mixins";

.bronze-incentives-loader-container {
  display: flex;
  flex-direction: column;
  padding: 0;

  &__header {
    display: flex;
    flex-flow: column;
  }

  &__row {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 3rem;
    align-items: flex-start;
    gap: 1.5rem;
    min-height: 15.625rem;

    @include mixins.less_than_desktop_inclusive {
      gap: 0.5rem;
      margin-bottom: 1.625rem;
    }

    &__box {
      min-width: calc(33.33% - 1rem);
      max-width: calc(33.33% - 1rem);
      flex-shrink: 0;
      flex-basis: 0;
      align-self: stretch;

      @include mixins.less_than_desktop_inclusive {
        min-width: 100%;
      }
    }
  }

  &__first {
    min-height: 15.625rem;
  }

  &__middle {
    min-height: 12.625rem;
    display: flex;
    flex-direction: column;

    &-container {
      width: 100%;
    }

    &-calculation {
      display: flex;
      flex-wrap: wrap;
      gap: 1.5rem;
      width: 100%;

      .leaf-tile {
        min-height: 4rem !important;
      }
    }
  }

  &__second {
    min-height: 21.875rem;
  }
}

.bronze-incentives-loader-fullpage {
  padding: 2.5rem;

  @include mixins.less_than_desktop_inclusive {
    padding: 1.5rem;
  }
}
