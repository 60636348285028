@use "../../scss/colors";
@use "../../scss/font";
@use "../../scss/mixins";

.MultiplierDetail {
  width: 32%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mixins.tablet_only {
    width: 100%;
  }

  @include mixins.md_m {
    width: 100%;
  }

  &__title {
    font-size: font.$size--large;
    font-weight: font.$weight--bold;
    padding-bottom: 0.25rem;
    height: max-content;
    text-align: center;

    &__info-button {
      padding-left: 0.5rem;
      border: none;
      background-color: #fff;
      cursor: pointer;
    }
  }

  &__subtitle {
    padding-bottom: 0.75rem;
    font-size: font.$size--big;
    font-weight: font.$weight--bold;
    line-height: font.$size--big_m;
    color: colors.$blue;
    text-align: center;

    @include mixins.tablet_only {
      padding-bottom: 0.3rem;
    }

    @include mixins.md_m {
      padding-bottom: 0.3rem;
    }
  }

  &__description {
    font-size: font.$size--medium;
    height: 2.625rem;
    margin-bottom: 0.75rem;
    text-align: center;

    @include mixins.tablet_only {
      height: fit-content;
      line-height: 1.5rem;
    }

    @include mixins.md_m {
      height: fit-content;
      line-height: 1.2rem;
    }
  }

  &__button {
    background-color: transparent;
    min-width: 16.125rem;
    border: 0.125rem solid colors.$amway_black;
    border-radius: 3.125rem;
    padding: 0.5rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: GTWalsheimPro, sans-serif;
    font-weight: font.$weight--bold;
    font-size: font.$size--standard;
    box-sizing: border-box;
    color: colors.$amway_black;

    @include mixins.tablet_only {
      font-size: font.$size--medium;
    }

    @include mixins.md_m {
      font-size: font.$size--medium;
      min-height: 2.75rem;
    }
  }

  &__button-icon {
    margin-left: 0.5rem;
    cursor: pointer;

    span {
      cursor: pointer;
    }
  }

  &__button-text {
    cursor: pointer;
  }

  .LeafButton {
    min-width: 16.125rem;
  }

  .leaf-modal {
    &__modal-desc {
      padding: 0 0.5rem;
    }

    &__modalHeader {
      text-align: center;
      margin: 0.5rem 0.25rem 1rem;

      @include mixins.md_m {
        h1 {
          font-size: font.$size--big_s;
        }
      }
    }

    &__modal-body {
      padding-bottom: 0;
    }

    @include mixins.md_m {
      &__modal-container {
        height: auto;
      }
    }
  }

  .icon-infoCircle {
    cursor: pointer;
  }
}
