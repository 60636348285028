@use "../../../common/scss/colors";
@use "../../../common/scss/mixins";
@use "../../../common/scss/font";

%progressBadgeRow {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 0.5rem;
  color: colors.$dark_grey_on_white;
}

.rubyPerformanceTile {
  min-width: 13.75rem;
  min-height: 12rem;
  height: 100%;
  border-radius: 0.75rem;
  border: 1px solid colors.$light_gray;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  background-color: colors.$white;

  .icon-checkCircle--md {
    align-self: baseline;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1.5rem;
    padding-bottom: 0.75rem;
  }

  &__title {
    font-size: 1.5rem;
    display: inline;
    font-weight: bold;
    margin: 0 0.5rem 1rem;

    button {
      background: transparent;
      border: none;
      display: inline-flex;

      &:hover,
      span:hover {
        cursor: pointer;
      }
    }
  }

  &__milestones {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;

    @include mixins.sm_l {
      flex-direction: column;
      gap: 0;
    }
  }

  &__progressBadges {
    display: flex;
    flex-direction: row;
    gap: 2.5rem;
    padding-bottom: 1.25rem;

    @include mixins.sm_l {
      flex-direction: column;
      gap: 0.5rem;
    }
  }

  &__progressBadgeRow {
    @extend %progressBadgeRow;

    svg,
    path {
      fill: colors.$disabled;
    }

    &--met {
      @extend %progressBadgeRow;

      color: colors.$light_green;

      svg,
      path {
        fill: colors.$light_green;
      }
    }
  }

  @include mixins.under_md_ms {
    .leaf-modal__modal-container {
      height: auto;
    }
  }

  .leaf-modal__modal-container {
    padding: 1rem 1rem 2rem;
  }

  .leaf-modal__title {
    text-align: center !important;
    font-size: font.$size--big_s;
  }

  &__rubyPvModal {
    &--body {
      text-align: center;
      font-size: font.$size--standard;
      line-height: 1.5rem;
    }
  }
}
