@use "../../../../common/scss/colors";
@use "../../../../common/scss/mixins";
@use "../../../../common/scss/font";

.bbi-metric {
  background-color: colors.$very_light_gray;

  &__content {
    padding-left: 2.5rem;

    @include mixins.less_than_desktop_inclusive {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  &__header {
    padding: 3rem 0 1.5rem;
    font-size: 2rem;
    font-weight: 700;

    // Tablet
    @include mixins.less_than_desktop_inclusive {
      font-size: font.$size--large;
    }

    // Mobile
    @include mixins.less_than_tablet {
      font-size: font.$size--big_s;
    }
  }

  &__incentive-calculation {
    width: 100%;
    padding: 0 2.5rem 0 0;

    @include mixins.less_than_desktop_inclusive {
      padding: 0;
    }
  }
}
