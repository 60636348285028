@use "../../scss/colors";

.IconPersonPlus {
  display: block;
  height: 2rem;
  width: 3rem;

  &--gray path {
    fill: colors.$light_gray;
  }

  &--green path {
    fill: colors.$light_green;
  }

  &--sm {
    display: flex;

    svg {
      height: 0.75rem;
      width: 0.75rem;
    }
  }

  &--md {
    display: flex;

    svg {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  &--lg {
    display: flex;

    svg {
      height: 2.5rem;
      width: 2.5rem;
    }
  }
}
