@use "../../../common/scss/colors";
@use "../../../common/scss/font";
@use "../../../common/scss/mixins";
@use "../../../common/scss/incentive-page";

.MonthlyPgg {
  @include incentive-page.baseLayout;

  &__banner {
    .BannerTile {
      height: 100%;
    }
  }

  .Tracking {
    &__header {
      margin-top: 0.6rem;
      padding-bottom: 1.5rem;
      text-align: center;

      @include mixins.less_than_tablet {
        padding-bottom: 1rem;
      }
    }

    &__icons {
      padding-top: 0;
    }
  }

  .TabSwitcher--column {
    flex-direction: row;
  }

  &__requirements-content {
    @include mixins.tablet_only {
      display: flex !important;
    }
  }

  &__requirements-sections,
  &__requirements-sections-line,
  &__requirements-sections-backline,
  &__requirements-sections-shortline {
    &-section {
      margin-top: 1.5rem;
      text-align: center;
      display: flex;
      flex-direction: column;
      border-radius: 0.75rem;
      border: 1px solid colors.$light_gray;

      @include mixins.under_md_ms {
        width: 100%;
        border: none;
      }

      &-title {
        display: block;
        text-align: left;
        font-size: font.$size--large;
        color: colors.$blue;
        font-weight: font.$weight--bold;

        @include mixins.tablet_only {
          font-size: font.$size--big_s;
        }

        @include mixins.less_than_tablet {
          font-size: font.$size--big;
        }
      }

      &-content {
        margin-top: 1rem;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-row: auto;
        gap: 1.25rem;

        .leaf-tile {
          &__title,
          .leaf-tag {
            text-align: left;
          }
        }

        @include mixins.tablet_only {
          width: 50%;
          grid-template-columns: repeat(2, 1fr);
        }

        @include mixins.less_than_tablet {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
  }

  &__requirements-sections-line,
  &__requirements-sections-backline {
    @include mixins.more_than_desktop_inclusive {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      margin: 1rem 0;
      padding: 1.125rem;

      &-section {
        margin-top: 0;

        &-title {
          display: none;
        }

        &-content {
          grid-template-columns: none;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: 1rem;
          height: 100%;
          margin-top: 0;

          .leaf-tile {
            &__title,
            .leaf-tag {
              justify-content: center;
              text-align: center;
            }
          }
        }
      }
    }
  }

  &__requirements-sections-shortline {
    @include mixins.more_than_desktop_inclusive {
      width: 66.66%;
    }

    @include mixins.from_tablet {
      display: flex;
      flex-direction: row;
      gap: 1.625rem;
      margin: 1rem 0;

      &-monthly {
        flex: 1 1;
      }

      &-annual {
        flex: 1 1;
      }

      &-section {
        margin-top: 0;

        &-title {
          display: none;
        }

        &-content {
          grid-template-columns: none;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: 1rem;
          margin-top: 0;

          .leaf-tile {
            &__title,
            .leaf-tag {
              justify-content: center;
              text-align: center;
            }
          }
        }
      }
    }
  }

  &__requirements-sections-line {
    @include mixins.more_than_desktop_inclusive {
      &-monthly {
        flex-basis: 33.33%;
      }
    }
  }

  &__requirements-sections-backline {
    @include mixins.more_than_desktop_inclusive {
      &-monthly {
        flex-basis: 66.66%;
      }

      &-annual {
        flex-basis: 33.33%;
      }
    }
  }
}
