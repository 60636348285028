@use "../../../../../common/scss/mixins";
@use "../../../../../common/scss/colors";
@use "../../../../../common/scss/font";

.RequirementModal {
  text-align: center;

  .leaf-table {
    th {
      min-width: 10rem;
    }
  }

  .leaf-modal {
    &__modal-container {
      height: auto;
      max-height: max-content;
    }

    &__title {
      font-size: font.$size--big_s;
    }

    &__modal-body {
      padding: 0 !important;
    }
  }

  &__body {
    max-height: 30rem;
  }

  &__loading {
    padding-top: 0.5rem;
    height: auto;
  }

  &__cell {
    display: flex;
    justify-content: center;
    align-items: center;

    &--inactive {
      font-size: font.$size--standard;
      font-weight: font.$weight--medium;

      @include mixins.sm_l {
        font-size: font.$size--small;
      }
    }

    &--active {
      font-size: font.$size--big;
      font-weight: font.$weight--bold;
      color: colors.$light_green;

      @include mixins.sm_l {
        font-size: font.$size--medium;
      }
    }

    &--activeSpMonths {
      font-size: font.$size--standard;
      font-weight: font.$weight--bold;
      color: colors.$light_green;

      @include mixins.sm_l {
        font-size: font.$size--medium;
      }
    }
  }
}
