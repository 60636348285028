@use "../../scss/colors";
@use "../../scss/font";
@use "../../scss/mixins";

.LeafTileValue {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  &__button {
    border: none;
    background-color: transparent;
    min-height: 2.75rem;
    min-width: 2.75rem;
    display: flex;
    justify-content: center;

    @include mixins.less_than_tablet {
      min-height: 2rem;
      min-width: 2rem;
    }

    &-icon {
      margin: auto 0;

      @include mixins.less_than_tablet {
        margin-top: 0.3rem;
      }

      .icon-plain-table {
        width: 2rem;
        height: 2rem;
        border-radius: 0.4rem;
        border: solid 1px colors.$light_gray;
        background-color: colors.$very_light_gray;
        cursor: pointer;
      }
    }

    &--hide-mobile {
      @include mixins.less_than_tablet {
        display: none;
      }
    }

    &--hide-desktop {
      display: none;

      @include mixins.less_than_tablet {
        display: block;
      }
    }
  }

  &__content-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  &__title {
    color: colors.$dark_grey_on_white;
    padding-bottom: 0.5rem;
    font-size: font.$size--medium;
    line-height: font.$size--big;
    font-weight: font.$weight--regular;

    @include mixins.less_than_tablet {
      padding-bottom: 0.2rem;
    }
  }

  &__value {
    font-size: font.$size--large;
    line-height: font.$size--huge;
    font-weight: font.$weight--bold;
    margin-top: auto;

    @include mixins.from_mobile_to_tablet_inclusive {
      font-size: font.$size--big;
      line-height: font.$size--big_s;
    }
  }

  &__symbol {
    display: none;

    @include mixins.less_than_tablet {
      display: block;
      height: 1rem;
      width: 1rem;
      margin: 0.7rem 1.5rem 0 0;
    }
  }

  .rectangle-loader {
    margin-bottom: 0;
  }
}
