@use "../../../../common/scss/colors";
@use "../../../../common/scss/font";
@use "../../../../common/scss/mixins";

.MonthlyAnnual {
  .leaf-tile__title {
    align-items: flex-start;

    span {
      margin-top: 0.25rem;
    }
  }

  &__centerAlign {
    text-align: center;

    .leaf-tile__title {
      justify-content: center;
    }
  }

  &__tiles-section {
    display: flex;
    margin: 1rem 0;
    border-radius: 0.75rem;
    border: 1px solid colors.$light_gray;

    h4 {
      font-size: font.$size--large;
      color: colors.$blue;
      margin: 1rem 0;

      @include mixins.md_l {
        font-size: font.$size--big_s;
      }
    }

    @include mixins.md_l {
      flex-direction: column;
      border: none;
    }
  }

  &__sub-headers {
    display: flex;
    margin-top: 1rem;

    h4 {
      font-size: font.$size--large;
      color: colors.$blue;
      text-align: center;
    }

    h4:nth-child(1) {
      flex-basis: 33.33%;
    }

    h4:nth-child(2) {
      flex-basis: 66.66%;
    }
  }

  &__Monthly {
    flex-basis: 33.33%;

    &--tiles {
      height: 100%;
    }
  }

  &__Annual {
    flex-basis: 66.66%;

    &--tiles {
      display: flex;
      height: 100%;

      @include mixins.md_l {
        flex-direction: column;
        gap: 1rem;
      }
    }
  }

  &__tile {
    @include mixins.md_l {
      max-width: 50%;
    }

    @include mixins.under_md_ms {
      max-width: none;
    }
  }

  &__vertical-line {
    display: flex;
    align-items: center;
    flex-basis: 4%;
    width: 1px;
    flex-shrink: 0;
    stroke-width: 1px;
    stroke: var(--30-Light-Gray-Rule-Lines, colors.$light_gray);

    @include mixins.md_l {
      display: none;
    }
  }
}
