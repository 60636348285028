@use "../../../common/scss/colors";
@use "../../../common/scss/font";
@use "../../../common/scss/mixins";

.PQRequirements {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__legend {
    display: flex;

    @include mixins.md_s {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 1rem;
    }

    &--container {
      display: flex;
      align-items: center;
      font-size: font.$size--standard;
      color: colors.$amway_black;
      font-weight: font.$weight--regular;
      padding: 0 1rem;
      gap: 0.25rem;
      border-right: 1px solid colors.$light_gray;

      @include mixins.md_s {
        padding: 0;
        border: none;
        font-size: font.$size--medium;
      }
    }

    &--first {
      padding-left: 0;
    }

    &--last {
      padding-right: 0;
      border-right: none;
    }
  }

  &__months {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 4.5rem;
    margin: 1rem 0;

    @include mixins.md_s {
      display: flex;
      flex-flow: row wrap;
      gap: 1rem 17%;
      height: fit-content;
      align-items: start;
      justify-content: space-between;
    }

    &--grid-switched {
      @include mixins.md_s {
        gap: 1rem 26%;
      }
    }

    &--container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 4rem;
      height: 100%;
      align-items: center;

      @include mixins.md_s {
        flex: 1 0 10%;
      }
    }
  }

  &__description {
    display: flex;
    margin-top: 1rem;
    font-size: font.$size--medium;
    color: colors.$dark_grey;
  }
}
