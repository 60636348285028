@use "../../../common/scss/colors";
@use "../../../common/scss/font";
@use "../../../common/scss/mixins";

.overview-content {
  width: 50%;
  padding: 0;

  @include mixins.less_than_desktop_inclusive {
    width: 100%;
    padding-right: 0;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 2.5rem;
  }

  &__item-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;

    h2 {
      font-size: 1.125rem;
      padding: 0;
    }

    button {
      background-color: transparent;
      border: none;
      color: colors.$amway_black;
      font-weight: font.$weight--bold;
      font-size: font.$size--standard;
      text-decoration: underline 0.125rem;
      text-underline-offset: 0.375rem;
      cursor: pointer;

      @include mixins.less_than_desktop_inclusive {
        font-size: font.$size--medium;
      }
    }
  }

  &__item-title {
    font-size: font.$size--big;
    display: flex;
    margin-bottom: 1rem;
  }

  &__item-description {
    font-size: font.$size--standard;
    line-height: 1.5rem;

    @include mixins.less_than_desktop_inclusive {
      font-size: font.$size--medium;
      line-height: 1.125rem;
    }
  }

  .overview-tile {
    :last-child {
      margin: 0;
    }

    @include mixins.less_than_tablet {
      border: none;
      padding-left: 0;
    }
  }
}
