@use "../../common/scss/colors";
@use "../../common/scss/mixins";

.bronzeGroupTracking {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__heading-group {
    display: flex;
    width: 100%;

    h1 {
      padding: 0;

      @include mixins.over__md_l {
        padding-left: 2.5rem;
        padding-bottom: 1rem;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5rem;

    button {
      margin-left: 1rem;
    }

    @include mixins.tablet_only {
      margin-left: 2rem;
    }

    @include mixins.less_than_tablet {
      margin-left: 1rem;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 2.5rem;
    min-height: 50vh;
    height: 100%;

    @include mixins.less_than_desktop_inclusive {
      margin: 0 0 0.75rem 1rem;
    }
  }

  &__long-date {
    margin-right: 3rem;
    color: colors.$dark_grey_on_white;
    white-space: nowrap;

    @include mixins.less_than_desktop_inclusive {
      display: none;
    }
  }

  .bronzeGroupTracking__info-button {
    border: none;
    background-color: colors.$white;
    margin-left: 0.75rem;

    @include mixins.under_md_ms {
      margin-right: 1rem;
    }

    button {
      display: flex;
    }

    .icon-infoCircle:hover {
      cursor: pointer;
    }
  }
}
