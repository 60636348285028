@use "../../common/scss/colors";
@use "../../common/scss/font";
@use "../../common/scss/mixins";

%toggleButton {
  border-radius: 6.25rem;
  font-family: font.$family--header;
  font-size: font.$size--standard;
  background: colors.$white;
  min-height: 2.25rem;
  height: max-content;
  place-items: center;
  justify-content: center;
  width: auto;
  padding: 0.3125rem 1rem;
  cursor: default;
  color: colors.$amway_black;
  font-weight: normal;
  border: 0.0625rem solid colors.$light_gray;
}

%toggle-container {
  display: flex;
  flex-direction: row;
  padding: 0.5rem 2.5rem 1rem;
  gap: 0.6rem;

  @include mixins.less_than_desktop_inclusive {
    padding: 0.5rem 1rem 1rem;
  }
}

.PerformanceDashboard {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;

  &__content {
    @include mixins.more_than_desktop_inclusive {
      padding: 0 1.5rem;
    }
  }

  &__heading {
    button {
      border: none;
      background-color: colors.$white;

      :hover {
        cursor: pointer;
      }
    }
  }

  &__toggleButton {
    @extend %toggleButton;

    &:hover {
      cursor: pointer;
      border: 0.0625rem solid colors.$amway_black;
      font-weight: font.$weight--medium;
    }

    &--active {
      @extend %toggleButton;

      border: 0.125rem solid colors.$amway_black;
      padding: 0.25rem 1rem;
      font-weight: font.$weight--bold;
    }
  }

  &__toggle-container {
    @extend %toggle-container;

    &--column {
      @extend %toggle-container;

      @include mixins.sm_l {
        flex-direction: column;

        &__toggleButton {
          width: 100% !important;
        }
      }
    }
  }
}
