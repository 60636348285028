@use "../../scss/font";
@use "../../scss/colors";

$progress_bar_width: 2rem;

.leafVerticalScaleWrapper {
  margin: 1rem;
}

.leafVerticalScale {
  display: flex;
  flex-direction: row;
  margin: 1rem auto;
  width: fit-content;
  height: 100%;

  &__headers {
    display: flex;
    column-gap: 2rem;
    width: 20rem;
    height: 1.5rem;
    font-size: font.$size--medium;
    margin: auto;
    margin-bottom: 1.5rem;
  }

  &__scaleHeader {
    flex-wrap: wrap;
    width: 30%;
    justify-content: center;
    margin: 0 1rem 0 -1rem;
    text-align: center;
    margin-left: 2rem;
  }

  &__legendHeader {
    width: 60%;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: 1rem;
    text-align: center;
  }

  &__progressbar {
    width: 2rem;
    height: 100%;
    background-color: colors.$background_purple;
    border-radius: 0.5rem;
    position: relative;
    overflow: hidden;
    margin-right: 0.5rem;
  }

  &__bar {
    background: colors.$dark_purple;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0%;
    box-sizing: border-box;
    transform-origin: bottom;
  }

  &__legend {
    display: flex;
    flex-direction: column-reverse;
    width: 16.25rem;
  }

  &__legendrow {
    flex: 1;
    display: flex;
    padding: 1rem;
    height: 3rem;
    border-top: 1px dashed colors.$light_gray_accent;
    align-items: center;
    justify-content: flex-end;

    &--active {
      border-color: colors.$dark_grey_on_white;
    }
  }

  &__legendrow:first-child {
    border-bottom: 1px dashed colors.$light_gray_accent;
  }

  &__legendLabel,
  &__legendLabel--active {
    width: 7.5rem;
    height: 2rem;
    background: colors.$background_purple;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: colors.$dark_purple;
    font-size: font.$size--medium;
  }
}

.leafVerticalScaleTick {
  position: absolute;
  display: flex;
  flex-direction: column-reverse;

  &__tickRow {
    flex: 1;
    flex-direction: row;
    display: flex;
  }

  &__tickLine,
  &__tickLine--met {
    border-top: 1px dashed colors.$medium_gray;
    display: flex;
    width: 2rem;
    height: 100%;

    &--met {
      border-color: white;
    }
  }

  &__tickRow:first-child {
    .leafVerticalScaleTick__tickLine {
      border-bottom: none;
    }
  }

  &__tickRow:last-child {
    .leafVerticalScaleTick__tickLine {
      border: none;
    }
  }

  &__tickLabel {
    display: flex;
    font-size: font.$size--medium;
    line-height: 0.875rem;
    height: 0.875rem;
    margin-top: -0.4375rem;
    padding: 0 0.5rem;
    background: white;
  }
}
