@use "../../../../common/scss/colors";
@use "../../../../common/scss/mixins";
@use "../../../../common/scss/font";

.PGGPerformanceBonusModal {
  text-align: center;

  &__badge {
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
    font-size: font.$size--medium;

    &__icon {
      display: flex;
      border-right: 1px solid colors.$light_gray_accent;
      padding: 0.2rem 0.5rem;

      &:last-child {
        border: none;
      }
    }

    .icon-shield--small {
      margin-right: 0.5rem;
    }

    @include mixins.under_md_ms {
      flex-wrap: wrap;
      justify-content: center;
      padding-bottom: 1rem;
      border-bottom: 1px solid colors.$light_gray;
      font-size: font.$size--standard;
      display: grid;
      grid-template-areas:
        "badge-1 badge-3"
        "badge-2 badge-4";

      &__icon {
        border: none;
        width: 8rem;
        display: flex;
        align-items: center;
      }
    }

    .badge-1 {
      grid-area: badge-1;
    }

    .badge-2 {
      grid-area: badge-2;
    }

    .badge-3 {
      grid-area: badge-3;
    }

    .badge-4 {
      grid-area: badge-4;
    }
  }

  &__shield {
    align-items: center;
    min-width: 8rem;
    display: flex;
    flex-direction: column;
    padding: 0.625rem;

    .icon-shield--large {
      height: 3rem;
      width: 3rem;
    }

    @include mixins.under_md_ms {
      height: auto;

      .icon-shield--large {
        height: 2.5rem;
        width: 2.5rem;
      }
    }
  }

  &__shield-month {
    font-size: font.$size--standard;
    color: colors.$dark_grey_on_white;

    @include mixins.under_md_ms {
      font-size: font.$size--medium;
    }
  }

  &__shield-amount {
    color: colors.$amway_black;
    font-size: font.$size--standard;

    @include mixins.under_md_ms {
      font-size: font.$size--small;
    }
  }

  &__section {
    border-bottom: 1px solid colors.$light_gray_accent;
    padding: 0.625rem;

    &--badges {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 1rem;

      @include mixins.under_md_ms {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &__title {
      width: 100%;
      font-size: font.$size--big_s;
      font-weight: font.$weight--medium;
      margin-top: 1rem;
      text-align: center;
      padding-bottom: 0.75rem;

      @include mixins.under_md_ms {
        text-align: left;
      }
    }

    &:last-child {
      border: none;
    }

    @include mixins.under_md_ms {
      border: none;
      gap: 0;
      padding: 0;
    }
  }

  .leaf-modal__modal-body {
    padding: 0;
  }

  .leaf-modal__modalHeader h1 {
    font-size: font.$size--big_s;
  }

  @include mixins.under_md_ms {
    .leaf-modal__modal-container {
      height: auto;
    }

    .leaf-modal__modalHeader h1 {
      padding-left: 1rem;
      padding-right: 1.875rem;
    }
  }
}
