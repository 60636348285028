@use "../../../../../common/scss/colors";
@use "../../../../../common/scss/font";
@use "../../../../../common/scss/mixins";

.BaselineFQModal {
  text-align: center;
  width: fit-content;

  &__table {
    display: flex;
    max-height: 16rem;
    scroll-behavior: smooth;
    overflow: auto;
  }

  &__totals {
    font-size: font.$size--big;
    font-weight: font.$weight--bold;
    display: grid;
    grid-template-columns: 54% 16% 14.5% 15%;
    text-align: center;
    width: 100%;
    margin-top: 1rem;

    span:first-child {
      text-align: left;
      margin-left: 1rem;
    }

    @media (max-width: (480px)) {
      grid-template-columns: 50% 15% 15% 15%;
    }

    @media (max-width: (425px)) {
      grid-template-columns: 45% 18% 18% 18%;
    }

    @media (max-width: (375px)) {
      grid-template-columns: 40% 18% 18% 18%;
    }

    @media (max-width: (340px)) {
      grid-template-columns: 35% 20% 20% 20%;
    }
  }

  .leaf-table {
    &__header {
      position: sticky;
      top: 0;
      border: none !important;
      word-break: normal !important;
    }

    &__header-default {
      @include mixins.md {
        word-break: normal;
      }
    }

    td:first-child {
      text-align: left !important;
      padding-left: 1rem;
    }

    th:first-child {
      text-align: left !important;
    }
  }

  .leaf-modal {
    &__modal-container {
      height: auto;
      max-height: max-content;
    }

    &__title {
      font-size: 1.3125rem;
      line-height: font.$size--large;
      font-weight: font.$weight--bold;
    }

    &__modal-desc {
      padding-bottom: 1rem;
      font-weight: font.$weight--bold;
      font-size: font.$size--big !important;
      color: colors.$blue;
    }
  }
}
