@use "../../../../../common/scss/colors";
@use "../../../../../common/scss/font";
@use "../../../../../common/scss/mixins";

.perfBonusSchedule-container {
  text-align: center;
  width: fit-content;

  &__table {
    display: flex;
    justify-content: center;
  }

  .leaf-table {
    &__header,
    &__row {
      font-size: font.$size--standard !important;
    }

    &__row {
      height: 3.25rem;
    }
  }

  .leaf-modal {
    &__modal-container {
      height: auto;
      max-height: max-content;
    }

    &__title {
      font-size: 1.3125rem;
      line-height: font.$size--large;
      font-weight: font.$weight--bold;
      padding: 0 1rem 1.25rem 0;
      word-wrap: break-word;

      @include mixins.md {
        padding: 0 0.625rem 0.625rem 0;
      }
    }
  }
}
