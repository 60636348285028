@use "../../scss/colors";
@use "../../scss/font";

.leaf-sort-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin: 0.5rem;
  height: 2rem;
  border: none;
  background-color: colors.$white;
  font-weight: font.$weight--bold;

  &__value {
    font-size: font.$size--medium;
    color: colors.$black;
  }
}

.pointer {
  cursor: pointer;
}
