@use "../../scss/colors";

.BaseTile {
  min-width: 13.75rem;
  min-height: 12rem;
  height: 100%;
  border-radius: 0.75rem;
  border: 1px solid colors.$light_gray;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  background-color: colors.$white;
}
