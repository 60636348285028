@use "../../scss/colors";

.leaf-eligibility {
  border-radius: 0.75rem;
  border: 1px solid colors.$light_gray;
  background: colors.$white;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  margin: 1rem 0;

  &__row {
    display: flex;
    justify-content: space-between;
    margin: 0.3rem 0;
  }

  &__title {
    color: colors.$amway_black;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    padding: 0.25rem 0;
  }

  &__date-range {
    color: colors.$dark_grey_on_white;
    font-size: 0.875rem !important;
    font-weight: 400;
    line-height: 1.125rem;
  }
}
