@use "../../../common/scss/colors";
@use "../../../common/scss/font";
@use "../../../common/scss/mixins";

.bronze-banner-toast {
  .leafBannerToast {
    border: none;
    background-color: colors.$light_gray_bg;
    border-radius: 0%;
    padding: 1rem;
    margin: 0;
    gap: 0.75rem;

    @include mixins.md_s {
      gap: 0.5rem;
    }
  }

  &__message {
    display: flex;
    gap: 0.5rem;
    line-height: 1.5rem;
    font-weight: font.$weight--medium;

    @include mixins.md_s {
      font-size: 0.875rem;
      line-height: 1.125rem;
    }
  }
}
