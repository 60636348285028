@use "../../scss/colors";
@use "../../scss/font";
@use "../../scss/mixins";

.leaf-learn-more-link {
  background-color: transparent;
  height: 2.75rem;
  border: 0.125rem solid colors.$amway_black;
  border-radius: 3.125rem;
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-family: font.$family--primary;
  font-weight: font.$weight--bold;
  font-size: font.$size--standard;
  box-sizing: border-box;

  &__icon {
    display: flex;
    margin: 0 0.3rem 0.1rem;
  }
}
