@use "../../scss/colors";
@use "../../scss/font";
@use "../../scss/mixins";

.MatrixFQModal {
  text-align: center;

  &__multiplier-section-title {
    font-size: font.$size--big_m;
    line-height: font.$size--huge;
    font-weight: font.$weight--bold;
  }

  &__multiplier-section-subtitle {
    font-size: font.$size--big;
    line-height: font.$size--big_m;
    font-weight: font.$weight--bold;
    color: colors.$blue;

    @include mixins.md {
      font-size: font.$size--standard;
    }
  }

  &__multiplier-section-table {
    margin-top: 1.5rem;
    overflow: auto;

    table,
    table th,
    table tr,
    table td {
      border-style: hidden !important;
      border-collapse: collapse;
    }

    table tr:first-child td {
      width: 42%;
    }

    table > tbody > tr > td:nth-child(2),
    table > tbody > tr > td:nth-child(3),
    table > tbody > tr > td:nth-child(4) {
      width: 15%;
      padding: 0;
    }
  }

  &__qualified-item {
    font-weight: font.$weight--bold;
    color: colors.$light_green;
    font-size: font.$size--big;

    @include mixins.md {
      font-size: font.$size--medium;
    }
  }

  &__tracking-item {
    font-weight: font.$weight--bold;
    color: colors.$data_viz_orange;
    font-size: font.$size--big;

    @include mixins.md {
      font-size: font.$size--medium;
    }
  }

  &__multiplier-legend {
    margin-top: 0.5rem;
    width: 100%;
    text-align: left;
    font-size: font.$size--medium;
    color: colors.$dark_grey_on_white;

    @include mixins.md {
      font-size: font.$size--small;
    }
  }

  .leaf-modal__modal-container {
    @include mixins.md {
      height: auto;
    }
  }

  .leaf-modal__modal-desc {
    font-size: font.$size--standard;

    @include mixins.md {
      font-size: font.$size--medium;
    }
  }
}
