@use "../../scss/colors";
@use "../../scss/mixins";

.IncentiveCalculation {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.85rem;

  @include mixins.less_than_tablet {
    background-color: colors.$white;
    flex-direction: column;
    border: 1px solid colors.$medium_gray;
    border-radius: 0.75rem;
    padding: 0.35rem 0;
  }

  .leaf-tile.desc-only {
    height: auto;
    flex-grow: 1;
    width: 100%;
    padding: 0.75rem;

    @include mixins.less_than_tablet {
      border: none;
      padding: 0.4rem 0.75rem;
    }
  }

  &__symbol {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1.5rem;

    @include mixins.tablet_only {
      margin: 0 0.5rem;
    }

    @include mixins.less_than_tablet {
      display: none;
    }
  }

  &__divider {
    display: none;

    @include mixins.less_than_tablet {
      display: block;
      background-color: colors.$amway_black;
      height: 0.0625rem;
      margin: 0.35rem 0.75rem;
    }
  }
}

#IncentiveCalculation-multiplier {
  border: 1px solid colors.$medium_gray;

  @include mixins.less_than_tablet {
    border: none;
  }
}
