@use "../../../scss/colors";

.leaf-checkmarks-full-container {
  margin-top: 1rem;
  min-height: initial;
  padding: 0.875rem 1rem;
}

.leaf-checkmarks-full {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 3px 0;
  }

  &__req-info {
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
  }

  &__req-met {
    color: colors.$light_green;
  }

  &__req-not-met {
    color: colors.$data_viz_orange;
  }
}
