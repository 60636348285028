@use "../../../common/scss/mixins";

.QCGRequirements {
  &__requirement {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1rem;
    height: 100%;
    margin-top: 1rem;
    width: 66.66%;

    @include mixins.md_l {
      width: 100%;
    }

    @include mixins.md {
      flex-direction: column;
    }
  }

  &__QCTile {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: flex-start;
    gap: 1rem;
    height: 100%;
    margin-top: 1rem;
    width: 66.66%;

    @include mixins.md_l {
      width: 100%;
    }

    &--loader {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .Growth-requirement {
    &__tile {
      display: flex;
      width: 33.33%;

      & > * {
        width: 100%;
      }

      @include mixins.md {
        width: 100%;
      }
    }

    &__wideTile {
      display: flex;
      width: 66.66%;

      @include mixins.md {
        width: 100%;
      }
    }

    &__tile-loader {
      .leaf-tile {
        height: 100%;
      }
    }
  }
}
