@use "../../common/scss/colors";
@use "../../common/scss/font";
@use "../../common/scss/mixins";

.Qcg {
  h2 {
    font-size: font.$size--large;

    @include mixins.less_than_tablet {
      font-size: font.$size--big_s;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .Tracking {
    &__header {
      text-align: center;
    }
  }

  &__container-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    @include mixins.tablet_only {
      flex-direction: column;
      gap: 1.2rem;
    }

    @include mixins.less_than_tablet {
      flex-direction: column;
      gap: 1rem;
    }
  }

  &__section-padding {
    padding: 0 2.5rem;

    @include mixins.tablet_only {
      padding: 0 1rem 1rem;
    }

    @include mixins.less_than_tablet {
      padding: 0 1rem 1rem;
    }
  }

  &__tracking {
    width: 34%;
    text-align: left;

    @include mixins.tablet_only {
      width: 100%;
      text-align: center;
    }

    @include mixins.md_m {
      width: 100%;
      text-align: center;
    }
  }

  &__calculation {
    background-color: #fafafa;
    margin: 1rem 0;
    padding-top: 3rem;
    padding-bottom: 1.5rem;

    @include mixins.from_mobile_to_tablet_inclusive {
      padding-top: 1.5rem;
    }
  }

  &__multiplication {
    width: 100%;
    margin-top: 1.5rem;
  }
}
