@use "../../../../common/scss/colors";
@use "../../../../common/scss/font";

.tracking-req-tile {
  display: flex;
  align-content: space-between;
  flex-direction: column;
  height: 100%;

  &__bold {
    font-weight: font.$weight--bold;
  }

  &__icon-container {
    display: flex;
    justify-content: space-evenly;
    margin: 1rem;
  }

  &__vertical-line {
    border-left: 0.125rem solid colors.$light_gray_accent;
    border-radius: 0.125rem;
  }

  &__desc-container {
    font-family: font.$family--header;
    font-style: normal;
    font-weight: font.$weight--regular;
    font-size: font.$size--standard;
    line-height: 1.5rem;
  }

  &__desc {
    margin-top: 0.5rem;
  }

  &__new-legs {
    font-weight: font.$weight--bold;
    color: colors.$dark_purple;
  }

  &--hidden {
    display: none;
  }
}
