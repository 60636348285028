@use "../../common/scss/colors";
@use "../../common/scss/font";
@use "../../common/scss/mixins";
@use "../../common/scss/animations";

.header {
  background-color: colors.$very_light_gray;
  box-shadow: 0 0.125rem 0.25rem colors.$light_background;
  color: colors.$amway_black;
  display: block;
  flex-direction: row;
  padding: 0.125rem 1.25rem;
  width: 100%;

  &__nav {
    display: flex;
    flex-direction: row;
    flex: 7;

    &--headless {
      justify-content: flex-end;
    }
  }

  &__logo {
    cursor: pointer;
    left: 10%;
    position: relative;
    top: 18%;
    width: 9.188rem;
    flex: 1;
  }

  &__heading {
    display: flex;
    font-size: font.$size--medium;
    font-weight: font.$weight--thin;
    justify-content: flex-end;
  }

  &__abo-info {
    padding-right: 0.625rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__country-code {
    margin: 0.3rem;
  }

  &__abo-number {
    margin-right: 0.3rem;
  }

  &__navList,
  &__smart-steps {
    flex: auto;
    font-size: font.$size--medium;
    font-weight: font.$weight--medium;
    list-style: none;
  }

  &__navList {
    padding: 1.25rem 2.5% 0 0.938rem;
    text-align: left;
    height: calc(5.6rem - 1.25rem);
    flex: 4;
    white-space: nowrap;
  }

  &__navList-ul {
    display: flex;
    list-style: none;
    gap: 0.625rem;
  }

  &__smart-steps {
    padding-top: 1.688rem;
    text-align: end;
    overflow-x: hidden;
    white-space: nowrap;
    flex-grow: 3;
  }

  &__smart-steps > div {
    justify-content: flex-end;
  }

  &__period-selector {
    margin-left: 1.25rem;
    margin-top: 0.938rem;
    flex: 1 1;

    &--headless {
      margin: 0.75rem 0;
    }

    @include mixins.sm {
      margin-bottom: 0.625rem;
      margin-left: 0;
    }
  }

  &__nav-links,
  &__smart-steps-links {
    color: colors.$white;
    text-decoration: none;
  }

  &__smart-steps-item {
    display: inline;
    line-height: 0.75rem;
    padding-right: 2%;
  }

  &__smart-steps-links:hover {
    border-bottom: 0.125rem solid colors.$white;
    padding-bottom: 0.125rem;
  }

  &__active {
    font-weight: font.$weight--bold;
  }

  .hidden {
    display: none;
  }

  &__amway-icon > span {
    margin-left: 1.25rem;
  }

  &__loaderWrapper {
    display: flex;
  }

  &__loader {
    height: 1rem;
  }
}
