@use "../../../common/scss/mixins";

.EligibilityPinTile {
  &__children {
    @include mixins.tablet_only {
      display: flex;
      justify-content: space-between;
    }
  }

  &__section-one {
    width: 100%;

    @include mixins.tablet_only {
      margin-right: 1rem;
    }
  }

  &__title {
    font-size: 1.5rem;
    display: flex;
    margin-bottom: 1rem;

    span {
      margin-right: 0.5rem;
    }
  }

  &__desc {
    margin-bottom: 1rem;

    @include mixins.tablet_only {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  &__section-two {
    width: 100%;
  }

  &__pins-container {
    display: flex;
    flex-direction: column;
  }

  &__viewEligibilityButton {
    width: 100%;
    margin: 1rem 0;
  }

  &__eligibilityPin {
    display: flex;
    flex-direction: column;

    & > * {
      align-self: center;
      width: 100%;
    }
  }
}

.tablet-only {
  @include mixins.tablet_only {
    display: block;
  }

  @include mixins.over__md_l {
    display: none;
  }

  @include mixins.under_md_ms {
    display: none;
  }
}

.mobile-desktop-only {
  max-width: 100%;
  margin-top: 1rem;
  margin-bottom: 0;

  @include mixins.over__md_l {
    display: block;
  }

  @include mixins.tablet_only {
    display: none;
  }

  @include mixins.under_md_ms {
    display: block;
  }
}
