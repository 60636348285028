@use "../../scss/colors";
@use "../../scss/font";

.leaf-color-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: colors.$background_purple;
  border-radius: 0.625rem;
  padding: 1rem;
  font-family: font.$family--header;
  font-size: 0.875rem;
  color: colors.$dark_purple;
}

.lower-content {
  font-weight: 700;
  font-size: font.$size--large;
}
