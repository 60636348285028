@use "../../scss/colors";

.IconPerson {
  &.gray path {
    fill: colors.$disabled;
  }

  &.green path {
    fill: colors.$light_green;
  }

  &.orange path {
    fill: colors.$data_viz_orange;
  }
}
