@use "../../../scss/colors";
@use "../../../scss/font";
@use "../../../scss/mixins";

.BadgeReq {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
  height: 100%;
  padding-bottom: 1rem;

  &__desc {
    text-align: center;
    font-weight: font.$weight--regular;
    font-size: font.$size--standard;
    line-height: 1.25rem;
    color: colors.$dark_grey_on_white;
    height: 5rem;

    @include mixins.less_than_tablet {
      height: auto;
      min-height: 2rem;
    }
  }

  &__storybook {
    max-width: 45%;
  }

  &__phantomLabel {
    height: 1.5rem;
    width: 1.5rem;
  }
}
