.EligibilityPin {
  height: 100%;

  &__pinTile {
    padding-top: 0.5rem;
  }

  &__tagsSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }

  &__tagLeft {
    display: flex;
    text-align: center;
    max-width: 50%;
    align-items: flex-start;
  }

  &__tagRight {
    display: flex;
    text-align: center;
    max-width: 50%;
    align-items: flex-end;
  }

  &__tag {
    &--left {
      display: flex;
      width: fit-content;
    }

    &--right {
      display: flex;
      width: fit-content;
    }
  }

  &__pinLabel {
    margin-top: 1rem;
  }

  &__amount {
    margin-top: 1rem;
    font-weight: 800;
    font-size: 1.5rem;
  }
}
