@use "../../../../common/scss/colors";
@use "../../../../common/scss/font";

.leg-requirements {
  display: flex;
  justify-content: center;

  &__leg-row-group {
    display: flex;
    flex-direction: column;
    gap: 0.875rem;
    padding: 1.5rem 0;
  }

  &__leg-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: font.$size--big;
    height: 2.25rem;
    color: colors.$dark_grey_on_white;
    font-weight: font.$weight--bold;
    gap: 0.5rem;

    &--current {
      color: colors.$amway_black;
    }
  }

  &__leg-row-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
  }

  &__leg-row-label {
    display: flex;
    flex-wrap: wrap;
    word-wrap: normal;
  }
}
