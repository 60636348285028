@use "../../../common/scss/colors";
@use "../../../common/scss/font";
@use "../../../common/scss/mixins";

.QCGBannerTile {
  &__not-qualified-banners-only {
    .BannerTile {
      @include mixins.md {
        width: 100%;
      }
    }
  }
}
