@use "../../../common/scss/colors";
@use "../../../common/scss/font";
@use "../../../common/scss/mixins";

.SSIBannerTile {
  height: 100%;

  &__bold-text {
    color: colors.$dark_purple;
    font-weight: font.$weight--bold;
  }

  &__payment-desc-one {
    margin-bottom: 0.5rem;
  }

  .BannerTile {
    height: 100%;

    &__bonusInfo {
      max-width: none;
    }

    &__bonusDetail {
      justify-content: space-between;
    }

    &__bannerContents {
      height: 100%;
    }
  }

  &__text-content-only {
    .BannerTile {
      &__bannerContents {
        display: block;
      }
    }
  }

  &__not-qualified-banners-only {
    .BannerTile {
      &__bonusDetail {
        justify-content: center;
        max-width: 35%;

        @include mixins.sm_xl {
          max-width: none;
        }
      }
    }
  }
}
