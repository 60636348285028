@use "../../../common/scss/font";
@use "../../../common/scss/mixins";

.BGT-Filter-Modal {
  top: auto;

  @include mixins.md_s {
    height: auto;
  }

  .tracking-filter {
    width: 100%;
    height: 100%;
  }

  .leaf-panel__header h1 {
    font-size: font.$size--standard;
  }

  .leaf-panel {
    &__body {
      min-height: 93% !important;
      height: 93% !important;
      overflow: scroll !important;
      padding: 0;

      @include mixins.md_s {
        min-height: 90% !important;
        height: 90% !important;
      }
    }

    &__right,
    &__fullsize {
      @include mixins.md_s {
        top: auto !important;
        bottom: 0 !important;
        height: auto !important;
        max-height: 100% !important;
      }
    }
  }
}
