@use "../../scss/font";

.leaf-progress-circle-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 16.875rem;
  padding-top: 0.625rem;

  &__text-container {
    height: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    top: 6.5rem;
  }

  &__title {
    font-weight: font.$weight--bold;
    line-height: font.$size--huge;
  }

  &__subtitle {
    font-size: font.$size--standard;
    line-height: font.$size--standard;
    line-break: normal;
    text-wrap: pretty;
    width: 9.375rem;
    text-align: center;
  }
}

.LeafProgressCircle {
  height: 16.25rem;
  width: 100%;
  display: flex;
  justify-content: center;

  &__Loader {
    padding-top: 1.5rem;
    height: 13.5rem;
    width: 13.5rem;
  }

  .pf-v5-c-chart {
    svg > g > path:nth-child(2) {
      fill: #f0f0f0 !important;
    }
  }
}
