@use "../../../common/scss/colors";
@use "../../../common/scss/mixins";
@use "../../../common/scss/font";

%shieldBase {
  svg {
    width: 3rem;
    height: 3rem;

    path {
      fill: colors.$light_gray;
    }
  }
}

.PPETrackProgress {
  height: 100%;

  .BaseTile {
    min-height: 10rem;
  }

  &__vertical-line {
    border-left: 1px solid colors.$light_gray;
    border-radius: 0.125rem;
  }

  &__title {
    text-align: center;
    font-weight: font.$weight--bold !important;
    font-size: font.$size--large;
  }

  &__progressText {
    font-size: font.$size--standard;
    font-weight: font.$weight--regular;
    line-height: 1.5rem;
    text-align: center;
  }

  &__subtext {
    padding-top: 0.5rem;
    text-align: center;
  }

  &__bold {
    font-weight: font.$weight--bold;
    font-size: font.$size--standard;
    color: colors.$dark_purple;

    &--met {
      font-weight: font.$weight--bold;
      font-size: font.$size--standard;
      color: colors.$light_green;
    }
  }

  &__icon-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 2rem 0;

    @include mixins.tablet_only {
      padding: 1rem 0;
    }

    @include mixins.less_than_tablet {
      font-size: font.$size--big_s;
      padding: 1rem 0;
    }
  }

  &__checksContainer {
    width: 100%;
    margin: 0 auto;
  }

  &__extraShield {
    @extend %shieldBase;

    &--met {
      @extend %shieldBase;

      path {
        fill: colors.$light_green !important;
      }
    }
  }
}
