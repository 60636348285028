@use "../../../../../common/scss/colors";
@use "../../../../../common/scss/font";
@use "../../../../../common/scss/mixins";

.bi-eligibility-slider-body {
  &__eligibility-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 1.5rem;
    border: 0.063rem solid colors.$light_gray;
    border-radius: 0.75rem;
    padding: 1rem;

    &__header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
      align-self: stretch;
    }

    &__dates {
      display: flex;
      flex-direction: column;
      line-height: 1.5rem;
    }
  }

  &__eligibility-program-detail {
    display: flex;
    padding: 2.5rem 0 1rem;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    &__title {
      font-size: 1.125rem;
      font-weight: font.$weight--bold;
      line-height: 1.3125rem;
      padding-bottom: 1rem;
    }

    &__body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      gap: 1.5rem;
      border: 0.063rem solid colors.$light_gray;
      border-radius: 0.75rem;
      padding: 1.5rem 1rem;
    }
  }
}

.bi-eligibility-slider-loader {
  &__card {
    border: 0.0625rem solid colors.$light_gray;
    border-radius: 0.75rem;
    padding: 1rem 1.5rem;
    margin-bottom: 0.5rem;
  }
}
