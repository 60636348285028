@use "../../../common/scss/colors";

.targetSchedule {
  &__wrapper {
    height: auto;
    border-radius: 0.75rem;
    border: 1px solid colors.$light_gray;
    padding: 1.5rem 1rem;
    display: flex;
    position: relative;
    flex-direction: column;
  }

  &__scaleWrapper {
    margin-top: 1.5rem;
  }

  &__description {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
