@use "../../scss/colors";
@use "../../scss/font";
@use "../../scss/mixins";

.TabSwitcher {
  display: flex;
  flex-direction: row;
  padding: 0 1rem 1rem;
  gap: 0.6rem;
  margin-left: 1.5rem;

  @include mixins.less_than_desktop_inclusive {
    margin-left: 0;
  }

  &__button {
    border-radius: 6.25rem;
    font-family: font.$family--header;
    font-size: font.$size--standard;
    background: colors.$white;
    min-height: 2.25rem;
    height: max-content;
    place-items: center;
    justify-content: center;
    width: auto;
    padding: 0.3125rem 1rem;
    cursor: default;
    color: colors.$amway_black;
    font-weight: normal;
    border: 0.0625rem solid colors.$light_gray;

    @include mixins.sm_l {
      width: 100%;
    }

    &:hover {
      cursor: pointer;
      border: 0.0625rem solid colors.$amway_black;
      font-weight: font.$weight--medium;
    }

    &--active,
    &--active:hover {
      border: 0.125rem solid colors.$amway_black;
      padding: 0.25rem 1rem;
      font-weight: font.$weight--bold;
    }
  }

  &--column {
    @include mixins.sm_l {
      flex-direction: column;
    }
  }
}
