@use "../../common/scss/colors";
@use "../../common/scss/font";
@use "../../common/scss/mixins";

@function calctitlewidth($count, $gap) {
  @return calc(((1 / $count) * 100%) - calc(($gap * ($count - 1)) / $count));
}

@mixin tile-width($cols, $gap) {
  min-width: calctitlewidth($cols, $gap);
  max-width: calctitlewidth($cols, $gap);
}

%toggleButton {
  border-radius: 6.25rem;
  font-family: font.$family--header;
  font-size: font.$size--standard;
  background: colors.$white;
  min-height: 2.25rem;
  height: max-content;
  place-items: center;
  justify-content: center;
  width: auto;
  padding: 0.3125rem 1rem;
  cursor: default;
  color: colors.$amway_black;
  font-weight: normal;
  border: 0.0625rem solid colors.$light_gray;
}

$row_gap_lg: 1.62rem;
$row_gap_md: 1.25rem;

.vision-bonus-container {
  height: 100%;
}

.vision-bonus {
  background-color: colors.$white;

  .leaf-tile {
    &__title {
      font-size: font.$size--large;

      @include mixins.less_than_tablet {
        font-size: font.$size--big_s;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__date {
    color: colors.$dark_grey_on_white;
    align-content: center;
    padding-right: 2.5rem;

    @include mixins.less_than_desktop_inclusive {
      display: none;
    }
  }

  &__content {
    @include mixins.over__md_l {
      padding: 0 2.5rem;
    }

    &__requirement {
      &__header {
        padding: 3rem 0 1.5rem;
        font-size: font.$size--large_l;
        font-weight: font.$weight--bold;

        @include mixins.less_than_desktop_inclusive {
          padding: 2.5rem 0 1rem;
          font-size: font.$size--large;
        }

        @include mixins.less_than_tablet {
          font-size: font.$size--big_s;
        }
      }

      &__content {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        align-items: flex-start;
        gap: $row_gap_lg;

        @include mixins.md_l {
          margin-bottom: 1.625rem;
          gap: $row_gap_md;
        }
      }

      &__content > * {
        @include tile-width(3, $row_gap_lg);

        flex-shrink: 0;
        flex-basis: 0;

        /* make tile fill parent container */
        align-self: stretch;

        @include mixins.md_l {
          @include tile-width(2, $row_gap_md);
        }

        @include mixins.md_s {
          min-width: 100%;
        }
      }
    }

    &__overview {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      padding-bottom: 1.5rem;
      gap: 1rem;

      @include mixins.less_than_desktop_inclusive {
        flex-direction: column;
      }

      .vision-bonus-banner {
        display: flex;
        font-size: font.$size--standard;
        line-height: font.$size--large;
        color: colors.$amway_black;
        flex-grow: 1;

        @include mixins.tablet_only {
          width: 100%;
        }

        @include mixins.md {
          width: 100%;
        }
      }
    }

    .TrackingProgressTile {
      width: 34%;
      text-align: left;

      @include mixins.tablet_only {
        width: 100%;
        text-align: center;
      }

      @include mixins.md_m {
        width: 100%;
        text-align: center;
      }
    }

    .LeafIncentiveBannerTile {
      width: 100%;

      &__title {
        line-height: 1.5rem;
      }

      @include mixins.less_than_desktop_inclusive {
        flex-direction: column;
      }

      @include mixins.less_than_tablet {
        flex-direction: column;
      }
    }

    .LeafIncentiveBannerTile__bannerContents {
      height: 100%;
    }

    .LeafIncentiveBannerTile__bonusDetail {
      justify-content: center;
    }

    @include mixins.less_than_desktop_inclusive {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
}
