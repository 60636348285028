@use "../../../common/scss/colors";
@use "../../../common/scss/font";
@use "../../../common/scss/mixins";
@use "../../../common/scss/breakpoints";

$banner-bg-img: url("../../../../public/images/lsi-banner.png");
$banner-gradient: linear-gradient(
  90deg,
  rgb(44 44 44 / 0%) 44.63%,
  rgb(44 44 44 / 64%) 61.31%,
  rgb(44 44 44 / 88%) 74.98%,
  colors.$amway_black 94.99%
);

.LSBanner {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 20.5rem;
  background: $banner-gradient, $banner-bg-img lightgray 50% / cover no-repeat;
  transform: scaleX(-1); // mirrored image

  &--automaticallyApproved {
    height: 40rem;
  }

  @include mixins.sm_xl {
    min-height: 14.5rem;
    background-image: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 90%) 100%), $banner-bg-img;
  }

  @include mixins.sm_l {
    align-items: flex-end;
  }

  &__learnMoreLink {
    width: 11rem;
    color: colors.$black;
    margin-top: 0.5rem;

    @include mixins.sm_l {
      width: 100%;
    }

    .leaf-learn-more-link {
      background-color: colors.$white;
    }
  }

  &__text-overlay {
    transform: scaleX(-1);
    display: flex;
    flex-direction: column;
    color: colors.$very_light_gray;
    max-width: 43.75rem;
    gap: 0.5rem;
    padding: 1.5rem 2.5rem;

    @include mixins.sm_l {
      line-height: 1.125rem;
      padding: 1rem;
      gap: 0.75rem;
    }

    &__headline {
      font-size: 2.625rem;
      font-weight: font.$weight--bold;
      line-height: 3rem;

      @include mixins.md_l {
        font-size: font.$size--large_l;
      }

      @include mixins.sm_l {
        font-size: font.$size--large;
        line-height: 1.75rem;
      }
    }

    &__sub-headline {
      font-size: font.$size--standard;
      font-weight: font.$weight--regular;
      font-style: italic;
      line-height: 1.5rem;
      color: colors.$very_light_gray;
      max-width: 28rem;

      @include mixins.md_l {
        font-size: font.$size--medium;
      }
    }
  }
}
