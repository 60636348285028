@use "../../scss/colors";
@use "../../scss/font";

.leaf-display-card {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  margin: 1rem 0;
  border: 0.063rem solid colors.$light_gray_bg;
  border-radius: 0.5rem;

  &__award-label {
    font-family: font.$family--primary;
    font-size: font.$size--medium;
    color: colors.$disabled_gray;
  }
}
