@use "../../../common/scss/colors";
@use "../../../common/scss/font";
@use "../../../common/scss/mixins";
@use "../../../common/scss/incentive-page";

.GcrPgg {
  @include incentive-page.baseLayout;

  &__banner {
    .BannerTile {
      height: 100%;
    }
  }

  &__errorTile {
    display: flex;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -45%);
  }

  &__yearToDate {
    width: 34%;
    text-align: left;
    height: auto;

    @include mixins.tablet_only {
      width: 100%;
      text-align: center;
    }

    @include mixins.md_l {
      width: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
    }
  }
}
