@use "../../scss/colors";
@use "../../scss/font";
@use "../../scss/mixins";

.Lcs {
  min-width: 13.75rem;
  min-height: auto;
  height: 100%;
  border-radius: 0.75rem;
  border: 1px solid colors.$medium_gray;
  background-color: colors.$white;
  padding: 1rem;
  display: flex;
  flex-direction: column;

  &__expanded {
    border: 1px solid colors.$medium_gray;

    @include mixins.tablet_only {
      padding-bottom: 2rem;
    }

    @include mixins.less_than_tablet {
      padding-bottom: 1.5rem;
    }
  }

  &__collapsed {
    border: 1px solid colors.$light_gray;
  }

  &__collapse-message,
  &__expand-message {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    text-align: center;
    font-size: font.$size--standard;
    font-weight: font.$weight--bold;
    color: colors.$blue;
    cursor: pointer;
    border: none;
    background-color: transparent;
    margin: 0.2rem 0;
  }

  &__collapse-message {
    margin: 0.2rem 0 1.2rem;
  }

  &__message-icon {
    padding-top: 0.35rem;
  }

  &__message-icon,
  &__message-text {
    cursor: pointer;
  }
}
