@use "../../../../../common/scss/colors";
@use "../../../../../common/scss/font";
@use "../../../../../common/scss/mixins";

.PreviousFQModal {
  text-align: center;

  .leaf-modal {
    &__modal-container {
      height: auto;
      max-height: max-content;
    }
  }

  &__body {
    max-height: 20rem;

    @include mixins.less_than_tablet {
      overflow: auto;
    }
  }

  &__loading {
    padding-top: 0.5rem;
    height: auto;
  }
}
