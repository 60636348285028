@use "../../../../common/scss/colors";
@use "../../../../common/scss/font";
@use "../../../../common/scss/mixins";

.OptionsLayout {
  .leaf-tile__title {
    align-items: flex-start;

    span {
      margin-top: 0.25rem;
    }
  }

  .FQsComparisonTile__heading {
    justify-content: flex-start !important;
  }

  &__Section {
    margin-top: 1rem;
  }

  &--h4 {
    color: colors.$blue;
    font-size: font.$size--large;

    @include mixins.md_l {
      font-size: font.$size--big_s;
    }
  }

  &__tiles {
    display: grid;
    gap: 1.25rem;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 1.5rem;

    @include mixins.md_l {
      grid-template-columns: repeat(2, 1fr);

      .FQsComparisonTile {
        grid-column: 1 / -1;
      }
    }

    @include mixins.under_md_ms {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
