.ttci-req-tracking-tile {
  &__description {
    padding: 0 5%;
  }

  &__tag {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }

  &__progress-tracker {
    margin-bottom: 1.5rem;
  }
}

#ppv-target-schedule-button {
  margin-top: 1.5rem;
}

#leaf-half-circle {
  display: flex;
  justify-content: center;
}
