@use "../../../scss/font";

.leaf-filter-by {
  display: flex;
  flex-direction: column;
  font-family: font.$family--primary;
  font-weight: font.$weight--medium;
  font-size: font.$size--standard;
  line-height: 1.5rem;
  list-style: none;
}
