@use "../../common/scss/colors";
@use "../../common/scss/font";
@use "../../common/scss/mixins";

.SSI {
  width: 100%;
  padding: 0;

  h2 {
    font-size: font.$size--large;

    @include mixins.less_than_tablet {
      font-size: font.$size--big_s;
    }
  }

  &__container,
  &__container-split {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__section-padding {
    padding: 0 2.5rem;

    @include mixins.tablet_only {
      padding: 0 1rem 1rem;
    }

    @include mixins.less_than_tablet {
      padding: 0 1rem 1rem;
    }
  }

  &__container-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    @include mixins.tablet_only {
      flex-direction: column;
      gap: 1.2rem;
    }

    @include mixins.less_than_tablet {
      flex-direction: column;
      gap: 1rem;
    }
  }

  &__banner {
    width: 65%;
    font-size: font.$size--standard;
    line-height: font.$size--large;
    color: colors.$amway_black;

    @include mixins.tablet_only {
      width: 100%;
    }

    @include mixins.md {
      width: 100%;
    }
  }

  &__tracking {
    width: 34%;
    text-align: left;

    @include mixins.tablet_only {
      width: 100%;
      text-align: center;
    }

    @include mixins.md_m {
      width: 100%;
      text-align: center;
    }
  }

  &__calculation {
    background-color: colors.$very_light_gray;
    margin: 1rem 0;
    padding-top: 3rem;
    padding-bottom: 1.5rem;

    .leaf-tile {
      background-color: colors.$white;
    }

    @include mixins.from_mobile_to_tablet_inclusive {
      padding-top: 1.5rem;
    }
  }

  &__requirements {
    width: 100%;
    margin-top: 1.5rem;
    padding-top: 2rem;
    padding-bottom: 1.5rem;

    @include mixins.md {
      margin-top: 0;
      padding-top: 1.5rem;
    }

    &-content {
      margin-top: 1.5rem;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-row: auto;
      gap: 1.25rem;

      @include mixins.tablet_only {
        grid-template-columns: repeat(2, 1fr);
      }

      @include mixins.md {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  &__eligibility-modal {
    font-weight: font.$weight--regular;

    h2 {
      margin-top: 1.75rem;
      font-weight: font.$weight--medium;
      font-size: font.$size--big_s;
    }

    &__date {
      padding: 0.25rem 0;
    }

    &__description {
      line-height: 1.5rem;
      margin-bottom: 0.25rem;
    }

    &__grid-container {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      gap: 0.75rem calc(calc(100% - (2.5rem * 4)) / (3));

      &__month {
        box-sizing: border-box;
        display: flex;
        flex-flow: column wrap;
        text-align: center;
        margin-top: 0.75rem;
        width: 2.5rem;
      }

      &__month > div {
        color: colors.$dark_grey_on_white;
        font-size: font.$size--medium;
        margin-top: 0.25rem;
      }
    }

    &__divider {
      margin: 1.5rem 0;
    }

    &__program-description h2 {
      line-height: 1.313rem;
      font-weight: font.$weight--bold;
      font-size: font.$size--big;
      margin-bottom: 0.75rem;
    }

    &__program-description p {
      line-height: 1.5rem;
    }

    &__program-description--last {
      margin-top: 0.75rem;
    }

    &__program-description a {
      font-weight: font.$weight--bold;
      color: colors.$blue;
      text-decoration: underline;
    }

    &__program-description a:visited {
      color: colors.$blue;
    }

    &__badge-legend {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, auto);
      grid-auto-flow: column;
      gap: 0.25rem;

      @include mixins.less_than_tablet {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, auto);
      }

      &__item {
        display: flex;
        gap: 0.25rem;
        align-items: center;

        & svg {
          flex-shrink: 0;
        }

        &__title {
          margin-top: 0.1rem;
        }
      }
    }
  }
}
