@use "../../../../common/scss/colors";
@use "../../../../common/scss/mixins";
@use "../../../../common/scss/font";

@function calctitlewidth($count, $gap) {
  @return calc(((1 / $count) * 100%) - calc(($gap * ($count - 1)) / $count));
}

@mixin tile-width($cols, $gap) {
  min-width: calctitlewidth($cols, $gap);
  max-width: calctitlewidth($cols, $gap);
}

$row_gap_lg: 1.62rem;
$row_gap_md: 1.25rem;

.BFIRequirements {
  &__header {
    font-weight: font.$weight--bold;
    font-size: font.$size--large_l;
    padding: 3rem 2.5rem 1.5rem 0;

    // Tablet
    @include mixins.less_than_desktop_inclusive {
      font-size: font.$size--large;
      padding: 2.5rem 2rem 1rem 0;
    }

    // Mobile
    @include mixins.less_than_tablet {
      font-size: font.$size--big_s;
      padding: 2.5rem 1rem 1rem 0;
    }
  }

  &__content {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    align-items: flex-start;
    gap: $row_gap_lg;

    @include mixins.md_l {
      margin-bottom: 1.625rem;
      gap: $row_gap_md;
    }
  }

  &__content > * {
    @include tile-width(3, $row_gap_lg);

    flex-shrink: 0;
    flex-basis: 0;

    /* make tile fill parent container */
    align-self: stretch;

    @include mixins.md_l {
      @include tile-width(2, $row_gap_md);
    }

    @include mixins.md_s {
      min-width: 100%;
    }
  }
}
