@use "../../scss/font";
@use "../../scss/mixins";

.leaf-error-message {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  place-content: center center;
  gap: 0.5rem;
  margin: 0.5rem;
  text-align: center;

  &--normal {
    font-size: font.$size--standard;
  }

  &--big {
    font-size: font.$size--big_m;
  }

  &--huge {
    font-size: font.$size--huge;
  }

  &--large {
    font-size: font.$size--large;
  }

  &--medium {
    font-size: font.$size--medium;
    font-weight: font.$weight--medium;
  }

  &--small {
    font-size: font.$size--small;
  }

  &--xl {
    font-size: font.$size--large_xl;
  }

  &--thin {
    font-weight: font.$weight--thin;
  }

  &--regular {
    font-weight: font.$weight--regular;
  }

  &--semibold {
    font-weight: font.$weight--semibold;
  }

  &--bold {
    font-weight: font.$weight--bold;
  }
}

.gap {
  margin-bottom: 1.5rem;

  @include mixins.sm_xl {
    margin-bottom: 0;
  }
}
